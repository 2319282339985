import { computed, reactive, ref, watch } from "vue";
import {
  ICategoryFilters,
  IFdcUserParticipantLien,
  IGroupFilter,
  IGroupImage,
  IGroupParticipant,
  IGroupTitles,
  IGroupUnread,
  IMessage,
  IModuleUsersInterne,
} from "@/pages/bureau/@models/fil.model";
import { IPagination } from "@/pages/bureau/@types/api";
import { IProjetSimple } from "@/pages/bureau/@models/projet.model";
import { UserModule } from "../modules/user.module";

class DefaultClass {
  public categories: IGroupTitles[] = [];
  public groupImages: IGroupImage = {};
  public groupUnread: {
    indexTable: number; //user index
    uid: string; //participant uid
    uidGroup: string;
    nbMessNonLu: number;
  }[] = [];
  public myGroupUnread: IGroupUnread = {};

  public usersParticipants: IFdcUserParticipantLien[] = [];

  public listener = false;
  public categoriesIsLoaded = false;

  public groupMessage: { [uidGroup: string]: IMessage[] } = {};
  public groupParticipants: { [uidGroup: string]: IGroupParticipant[] } = {};
  public groupMessagePagination: IPagination | any = {};
  public groupFilter: { [uidGroup: string]: IGroupFilter } = {};

  public moduleProjets: IProjetSimple[] = [];
  public moduleUsersInterne: IModuleUsersInterne[] = [];

  public filters: ICategoryFilters = { indexProjet: 0 };
  public actualizeImagesTimestamp = 0;

  constructor() {
    //
  }

  public getMyParticipantsUids() {
    return this.usersParticipants
      .filter((user) => {
        return user.index_user == UserModule.index;
      })
      .map((user) => {
        return user.uid_participant;
      });
  }

  /**
   *  permet de comparer les groupes non lus actuels avec les groupes non lus récupérés
   */
  public groupUnreadIsDifferent(
    groupArray: {
      uidGroup: string;
      nbMessNonLu: number;
    }[]
  ) {
    const myGroupUnread = this.myGroupUnread;
    const groupUnreadObject = Object.keys(myGroupUnread)
      .sort()
      .map((key) => {
        return {
          uidGroup: key,
          nbMessNonLu: myGroupUnread[key],
        };
      });
    const groupArraySorted = groupArray
      .map((group) => {
        return {
          uidGroup: group.uidGroup,
          nbMessNonLu: group.nbMessNonLu,
        };
      })
      .sort((a, b) => {
        return a.uidGroup.localeCompare(b.uidGroup);
      });
    return (
      JSON.stringify(groupUnreadObject) != JSON.stringify(groupArraySorted)
    );
  }

  public updateGroupsTimestamps(objs: { uidGroup: string; TSmodif: string;timestamp:string }[]) {
    console.log("updateGroupsTimestamps", objs);
    for (const obj of objs) {
      for (const category of this.categories) {
        for (const group of category.groups) {
          if (group.uidGroup === obj.uidGroup) {
            const _ts = obj.timestamp>obj.TSmodif?obj.timestamp:obj.TSmodif;
            if (!group.TSLastModif || _ts > group.TSLastModif) {
               group.TSLastModif = _ts;
            }
          }
        }
      }
    }
  }
}
const FilClass = new DefaultClass();

export default reactive(FilClass);

export enum EFilType {
  PROJET = "PROJET",
  INTERNE = "INTERNE",
  INTERVENANT = "INTERVENANT",
  INTERVENANT_PROJET = "INTERVENANT_PROJET",
  TACHE = "TACHE",
}
