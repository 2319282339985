import {
  IProjetTravaux,
  IProjetTravauxDoc,
  IProjetTravauxEntreprise,
  IProjetTravauxEntreprise_Synthese,
  IProjetTravauxFormatted,
  IProjetTravauxFtm,
  IProjetTravauxLot,
  IProjetTravauxMarche,
  IProjetTravauxPrime,
  IProjetTravauxSituation,
  IProjetTravauxStt,
  IProjetTravauxType,
} from "@/@models/projets";
import { projetsService } from "@/api";
import { downloadService } from "@/api/download";
import {
  formatNom,
  getUrlOfBlob,
  isValidStatus,
  TEMPORARY_isDev,
} from "@/utils";
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import { useToast } from "vue-toastification";
import router from "@/pages/ao/router";
import { IconType } from "@/components/global";

const toastification = useToast();

const travaux = ref<IProjetTravaux[]>([]);
const moas = ref<IProjetTravauxEntreprise[]>([]);
const entreprises = ref<IProjetTravauxEntreprise[]>([]);
const titulaires = ref<IProjetTravauxEntreprise[]>([]);
const lots = ref<IProjetTravauxLot[]>([]);

const estMoa = ref(false); //pas encore utilisé
const estInterSociete = ref(false); //pas encore utilisé

const possedeMarches = ref(false);
const possedeSituations = ref(false);

export function useTravaux() {
  const tabs = computed<
    { text: string; value: IProjetTravauxType; path: string }[]
  >(() =>
    [
      {
        text: "Synthèse par lot",
        value: 4 as IProjetTravauxType,
        enabled: true,
        path: "projet_travaux_syntheses_lots",
      },
      {
        text: "Synthèse par entreprise",
        value: 5 as IProjetTravauxType,
        enabled: true,
        path: "projet_travaux_syntheses_entreprises",
      },
      {
        text: "Marchés",
        value: 0 as IProjetTravauxType,
        enabled: true,
        path: "projet_travaux_marches",
      },
      {
        text: "Situations",
        value: 1 as IProjetTravauxType,
        enabled: true,
        path: "projet_travaux_situations",
      },
      {
        text: "Sous-traitants",
        value: 2 as IProjetTravauxType,
        enabled: true,
        path: "projet_travaux_stt",
      },
      {
        text: "Travaux modificatifs",
        value: 3 as IProjetTravauxType,
        enabled: true,
        path: "projet_travaux_ftm",
      },
      {
        text: "Primes et pénalités",
        value: 6 as IProjetTravauxType,
        enabled: true,
        path: "projet_travaux_primes",
      },
    ].filter((tab) => tab.enabled)
  );

  const actualIndex = computed<IProjetTravauxType>(() => {
    const routeName = router.currentRoute.value.name;
    return tabs.value.find((tab) => tab.path == routeName)?.value;
  });

  function clear() {
    travaux.value = [];
    moas.value = [];
    entreprises.value = [];
    titulaires.value = [];
    lots.value = [];
    estMoa.value = false;
    estInterSociete.value = false;
  }

  async function fetchMarches(uidProjet: string) {
    clear();
    try {
      const { status, data } = await projetsService.getProjetMarches(uidProjet);

      if (isValidStatus(status)) {
        travaux.value = data.travaux;
        moas.value = data.moas;
        entreprises.value = data.entreprises;
        lots.value = data.lots;
        estMoa.value = data.estMoa;
        estInterSociete.value = data.estInterSociete;
      }
    } catch (err) {
      //
    }
    return travaux.value.length > 0;
  }

  async function fetchSituations(uidProjet: string) {
    clear();
    try {
      const { status, data } = await projetsService.getProjetSituations(
        uidProjet
      );

      if (isValidStatus(status)) {
        travaux.value = data.travaux;
        moas.value = data.moas;
        entreprises.value = data.entreprises;
        lots.value = data.lots;
        estMoa.value = data.estMoa;
        estInterSociete.value = data.estInterSociete;
      }
    } catch (err) {
      //
    }
    return travaux.value.length > 0;
  }

  async function fetchSituationByIndex(index: number) {
    try {
      const { status, data } = await projetsService.getSituation(index);
      if (isValidStatus(status)) {
        return data.situation;
      }
    } catch (err) {
      //
    }
    return null;
  }

  async function fetchPrimes(uidProjet: string) {
    clear();
    try {
      const { status, data } = await projetsService.getProjetPrimes(uidProjet);

      if (isValidStatus(status)) {
        travaux.value = data.travaux;
        moas.value = data.moas;
        entreprises.value = data.entreprises;
        lots.value = data.lots;
        estMoa.value = data.estMoa;
        estInterSociete.value = data.estInterSociete;
      }
    } catch (err) {
      //
    }
    return travaux.value.length > 0;
  }

  async function fetchSyntheseLotsByLot(uid_projet: string, uid_lot: string) {
    try {
      const { status, data } = await projetsService.getProjetSyntheseLots(
        uid_projet,
        uid_lot
      );
      if (isValidStatus(status)) {
        possedeMarches.value = data.possedeMarches;
        possedeSituations.value = data.possedeSituations;
        return data;
      }
    } catch (err) {
      //
    }
    return null;
  }
  async function fetchSyntheseEntrepriseByIndexBib(
    uid_projet: string,
    indexBib: number
  ) {
    try {
      const { status, data } =
        await projetsService.getProjetSyntheseEntreprises(uid_projet, indexBib);
      if (isValidStatus(status)) {
        return data?.synthese;
      }
    } catch (err) {
      //
    }
    return null;
  }
  async function fetchStt(uidProjet: string) {
    clear();
    try {
      const { status, data } = await projetsService.getProjetStt(uidProjet);

      if (isValidStatus(status)) {
        travaux.value = data.travaux;
        moas.value = data.moas;
        entreprises.value = data.entreprises;
        lots.value = data.lots;
        titulaires.value = data.titulaires;
        estMoa.value = data.estMoa;
        estInterSociete.value = data.estInterSociete;
      }
    } catch (err) {
      //
    }
    return travaux.value.length > 0;
  }

  async function fetchFtm(uidProjet: string) {
    clear();
    try {
      const { status, data } = await projetsService.getProjetFtm(uidProjet);

      if (isValidStatus(status)) {
        travaux.value = data.travaux;
        moas.value = data.moas;
        entreprises.value = data.entreprises;
        lots.value = data.lots;
        estMoa.value = data.estMoa;
        estInterSociete.value = data.estInterSociete;
      }
    } catch (err) {
      //
    }
    return travaux.value.length > 0;
  }
  async function fetchSynthesesLots(uidProjet: string) {
    clear();
    try {
      const { status, data } = await projetsService.getProjetSynthesesLots(
        uidProjet
      );

      if (isValidStatus(status)) {
        entreprises.value = data.entreprises;
        lots.value = data.lots;
        travaux.value = data.travaux.sort(
          (a, b) =>
            lots.value[a?.uid_lot]?.ordre - lots.value[b?.uid_lot]?.ordre
        );
        estMoa.value = data.estMoa;
        estInterSociete.value = data.estInterSociete;
      }
    } catch (err) {
      //
    }
    return travaux.value.length > 0;
  }
  async function fetchSynthesesEntreprises(uidProjet: string) {
    clear();
    try {
      const { status, data } =
        await projetsService.getProjetSynthesesEntreprises(uidProjet);

      if (isValidStatus(status)) {
        lots.value = data.lots;
        travaux.value = data.travaux.sort((a, b) =>
          a?.nom_entreprise.localeCompare(b?.nom_entreprise)
        );
        estMoa.value = data.estMoa;
        estInterSociete.value = data.estInterSociete;
      }
    } catch (err) {
      //
    }
    return travaux.value.length > 0;
  }
  async function downloadDocuments(indexs: number[]) {
    const documents = [];
    for (const index of indexs) {
      const travailFormatted =
        formattedTravauxCommuns.value.travauxFormatted.find(
          (travail) => travail.index == index
        );
      if (travailFormatted) {
        documents.push({
          index: index,
          name: formatNomPiece(travailFormatted, actualIndex.value),
        });
      }
    }
    if (actualIndex.value < 4 && documents.length > 0) {
      const { url } = await fetchPiece(documents);

      if (url) {
        const a = document.createElement("a");
        a.href = url;
        a.download =
          documents.length == 1 ? documents[0].name + ".pdf" : "archive.zip";
        a.click();
      }
    }
  }
  async function printDocuments(indexs: number[]) {
    const documents = [];
    for (const index of indexs) {
      const travailFormatted =
        formattedTravauxCommuns.value.travauxFormatted.find(
          (travail) => travail.index == index
        );
      if (travailFormatted) {
        documents.push({
          index: index,
          name: new Date().getTime() + "_print_" + index,
        });
      }
    }

    if (actualIndex.value < 4 && documents.length == 1) {
      const { url } = await fetchPiece(documents);
      if (url) {
        const iframe = document.createElement("iframe");
        document.body.appendChild(iframe);

        iframe.style.display = "none";
        iframe.src = url;
        iframe.onload = function () {
          setTimeout(function () {
            iframe.focus();
            iframe.contentWindow.print();
          }, 500);
        };
      }
    }
  }

  async function downloadDocSidePanel(doc: IProjetTravauxDoc) {
    if (doc.index < 0) {
      await downloadDocuments([doc.index * -1]);
    } else {
      const { url } = await fetchExterneDoc(doc.index);
      if (url) {
        const a = document.createElement("a");
        a.href = url;
        a.download = doc.nom;
        a.click();
      }
    }
  }
  async function previewDocSidePanel(doc: IProjetTravauxDoc) {
    if (doc.index < 0) {
      return (
        await fetchPiece([
          {
            index: doc.index * -1,
            name: doc.nom,
          },
        ])
      ).url;
    } else {
      return (await fetchExterneDoc(doc.index)).url;
    }
  }

  async function fetchPiece(
    items: { index: number; name: string }[]
  ): Promise<{ blob: Blob; url: string }> {
    try {
      const blob = await downloadService.downloadPdfsTravaux({
        documents: items,
        type: actualIndex.value,
      });
      const url = getUrlOfBlob(blob);
      return { blob, url };
    } catch (error) {
      toastification.error("Erreur lors de la récupération du document");
      return { blob: null, url: "" };
    }
  }
  async function fetchExterneDoc(
    index: number
  ): Promise<{ blob: Blob; url: string }> {
    try {
      const blob = await downloadService.downloadDocTable(index);
      const url = getUrlOfBlob(blob);
      return { blob, url };
    } catch (error) {
      toastification.error("Erreur lors de la récupération du document");
      return { blob: null, url: "" };
    }
  }
  const formattedTravauxCommuns = computed<{
    travauxFormatted: IProjetTravauxFormatted[];
    type: IProjetTravauxType;
  }>(() => {
    const marches = ((travaux.value ?? []) as IProjetTravauxMarche[]).map(
      (travail) => {
        return {
          nom: [
            lots.value[travail.uid_lot]?.code,
            lots.value[travail.uid_lot]?.nom,
          ]
            .filter(Boolean)
            .join(" - "),
          entreprise: travail.entreprise,
          ordre: travail.ordre,
          ordreLot: lots.value[travail.uid_lot]?.ordre,
          type: travail.type,
          index: travail.index,
          date: travail.date,
          montant_ht: travail.montant_ht,
          montant_ttc: travail.montant_ttc,
          ref: travail.numero_reference_marche,
          nomTravaux: travail.nom,
          entrepriseTravaux: entreprises.value[travail.index_entreprise],
          moa: moas.value[travail.index_moa],
          date_devis: travail.date_devis,
          tranche: travail?.tranche,

          index_entreprise: travail.index_entreprise,
          index_titulaire: 0,
          index_moa: travail.index_moa,
          uid_lot: travail.uid_lot,
        } as IProjetTravauxFormatted;
      }
    );
    const situations = ((travaux.value ?? []) as IProjetTravauxSituation[]).map(
      (travail) => {
        return {
          nom: [
            lots.value[travail.uid_lot]?.code,
            lots.value[travail.uid_lot]?.nom,
          ]
            .filter(Boolean)
            .join(" - "),
          entreprise: travail.entreprise,
          ordre: travail.ordre,
          ordreLot: lots.value[travail.uid_lot]?.ordre,
          type: travail.type,
          index: travail.index,
          date: travail.date,
          montant_ht: travail.montant_ht,
          montant_ttc: travail.montant_ttc,
          montant_paye: travail.paye,
          nomTravaux: travail.libelle ?? "" ?? travail.type,
          entrepriseTravaux: entreprises.value[travail.index_entreprise],
          moa: moas.value[travail.index_moa],
          libelle: travail.libelle ?? "",
          index_entreprise: travail.index_entreprise,
          index_titulaire: 0,
          index_moa: travail.index_moa,
          uid_lot: travail.uid_lot,
        } as IProjetTravauxFormatted;
      }
    );

    const stt = ((travaux.value ?? []) as IProjetTravauxStt[]).map(
      (travail) => {
        return {
          nom: [
            lots.value[travail.uid_lot]?.code,
            lots.value[travail.uid_lot]?.nom,
          ]
            .filter(Boolean)
            .join(" - "),
          sous_traitant: entreprises.value[travail.index_entreprise],
          ordre: travail.ordre,
          ordreLot: lots.value[travail.uid_lot]?.ordre,
          index: travail.index,
          date: travail.date,
          montant_ht: travail.montant_ht,
          montant_ttc: travail.montant_ttc,
          montant_paye: 0,
          ref: travail.numero_acte_special,
          nomTravaux: travail.nom,
          entrepriseTravaux: entreprises.value[travail.index_entreprise],
          titulaire: titulaires.value[travail.index_titulaire],
          tranche: travail?.tranche,
          libelle: travail.nom,
          index_entreprise: travail.index_entreprise,
          index_titulaire: travail.index_titulaire,
          index_moa: travail.index_moa,
          uid_lot: travail.uid_lot,
          reste_a_payer:
            entreprises.value[travail.index_entreprise]?.reste_a_payer,
        } as IProjetTravauxFormatted;
      }
    );

    const ftm = ((travaux.value ?? []) as IProjetTravauxFtm[]).map(
      (travail) => {
        return {
          nom: [
            lots.value[travail.uid_lot]?.code,
            lots.value[travail.uid_lot]?.nom,
          ]
            .filter(Boolean)
            .join(" - "),
          ordre: travail.ordre,
          ordreLot: lots.value[travail.uid_lot]?.ordre,
          index: travail.index,
          date: travail.date,
          montant_ht: travail.montant_ht,
          montant_ttc: travail.montant_ttc,
          nomTravaux: travail.libelle,
          entrepriseTravaux: entreprises.value[travail.index_entreprise],
          tranche: travail?.tranche,
          libelle: travail.libelle,
          statut: travail.statut,
          index_statut: travail.index_statut,
          origine: (travail.origine ?? "").split("(")[0],
          index_entreprise: travail.index_entreprise,
          index_moa: travail.index_moa,
          uid_lot: travail.uid_lot,
          incidence: travail.incidence,
          localisation: travail.localisation,
          num_devis: travail.num_devis,
          date_devis: travail.date_devis,
          statut_index_marche: travail.statut_index_marche,
        } as IProjetTravauxFormatted;
      }
    );
    const primes = ((travaux.value ?? []) as IProjetTravauxPrime[]).map(
      (travail) => {
        return {
          nom: [
            lots.value[travail.uid_lot]?.code,
            lots.value[travail.uid_lot]?.nom,
          ]
            .filter(Boolean)
            .join(" - "),
          entreprise: entreprises.value[travail.index_entreprise]?.nom,
          ordre: parseInt((travail?.date || "").split("-").join("") || "0"),
          ordreLot: lots.value[travail.uid_lot]?.ordre,
          type: getTravauxPrimeType(travail.type as any as number),
          index: travail.index,
          date: travail.date,
          montant_ht:
            travail.montant_ht *
            ([2, 3].includes(travail.type as any as number) ? 1 : -1),
          montant_ttc:
            travail.montant_ttc *
            ([2, 3].includes(travail.type as any as number) ? 1 : -1),
          entrepriseTravaux: entreprises.value[travail.index_entreprise],
          libelle: travail.nom ?? "",
          index_entreprise: travail.index_entreprise,
          uid_lot: travail.uid_lot,
          nb_jours: travail.nb_jour,
          code: lots.value[travail.uid_lot]?.code,
          pas_comptabiliser: travail.pas_comptabiliser,
          commentaire:travail.comment,
          revalorisable:travail.revalorisable>0,
          tva:travail.tva,
        } as IProjetTravauxFormatted;
      }
    );

    //sort by ordreLot and ordre
    return {
      travauxFormatted: (actualIndex.value == 0
        ? marches
        : actualIndex.value == 1
        ? situations
        : actualIndex.value == 2
        ? stt
        : actualIndex.value == 3
        ? ftm
        : actualIndex.value == 6
        ? primes
        : []
      ).sort((a, b) => {
        if (a.lotOrdre == b.lotOrdre) {
          return a.ordre - b.ordre;
        }
        return a.lotOrdre - b.lotOrdre;
      }),
      type: actualIndex.value,
    };
  });

  return {
    downloadDocuments,
    printDocuments,

    fetchPiece,
    downloadDocSidePanel,
    previewDocSidePanel,

    fetchMarches,
    fetchSituations,
    fetchPrimes,
    fetchStt,
    fetchFtm,
    fetchSynthesesLots,
    fetchSynthesesEntreprises,

    possedeMarches,
    possedeSituations,

    fetchSituationByIndex,
    fetchSyntheseLotsByLot,
    fetchSyntheseEntrepriseByIndexBib,

    formattedTravauxCommuns,
    travaux,
    tabs,
    actualIndex,

    moas,
    lots,
    entreprises,
    titulaires,
  };
}

export function formatNomPiece(
  travailFormatted: IProjetTravauxFormatted,
  type: IProjetTravauxType
): string {
  if (!travailFormatted) {
    return "";
  }

  let nomTravail = "";

  switch (type) {
    case 0:
      nomTravail = travailFormatted.nomTravaux;
      break;
    case 1:
      nomTravail =
        "Situation " +
        travailFormatted.type +
        " - " +
        (moas.value[travailFormatted.index_moa]?.nom ?? "");
      break;
    case 2:
      nomTravail = travailFormatted.libelle;
      break;
  }
  const code =
    travailFormatted.nom.split(" / ").length > 1
      ? travailFormatted.nom.split(" / ")[0]
      : "";
  return formatNom([["Lot", code].join(" "), nomTravail].join(" - "), 60);
}

export function getTravauxFtmStatutBiconOption(item: IProjetTravauxFormatted): {
  name: IconType;
  class: string;
} | null {
  switch (item?.index_statut) {
    case 1:
      return {
        name: "valide", //acceptee
        class: "text-warning",
      };
    case 3:
      return {
        name: "cancel", //refusee
        class: "text-danger",
      };
    case 2:
      return {
        name: "valide", //integree
        class: "text-success",
      };
    default:
      return null;
  }
}

export function getTravauxPrimeType(index: number) {
  switch (index) {
    case 1:
      return "Pénalités";
    case 2:
      return "Primes";
    case 3:
      return "Indemnités";
    case 4:
      return "Retenues";
    default:
      return "";
  }
}
