<template>
  <slot name="action" :open="open" :hide="hide" />
  <Transition
    enter-active-class="duration-100 ease-in"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="duration-200 ease-out"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-if="showModal"
      tabindex="-1"
      aria-hidden="true"
      class="fixed inset-0 z-[69] flex backdrop-blur-sm"
    >
      <!-- BACKDROP -->
      <div
        @click="handleBackdropClick($event)"
        @contextmenu.prevent="() => {}"
        oncontextmenu="return false"
        tabindex="-1"
        class="fixed inset-0 m-0 p-0 opacity-60"
      />

      <Transition
        appear
        enter-active-class="duration-200 ease-out"
        enter-from-class="scale-95 translate-y-20"
        enter-to-class="scale-100 translate-y-0"
      >
        <!-- MODAL -->
        <div
          ref="modalEl"
          tabindex="-1"
          v-bind="$attrs"
          :class="[
            { ' max-w-2xl rounded-lg md:h-auto': !fullscreen },
            { ' h-full w-full rounded-md': fullscreen },
            { 'border-2': border },
          ]"
          class="relative z-70 m-auto box-border flex max-h-full max-w-full flex-col overflow-hidden rounded-2xl bg-element text-left text-base opacity-100 shadow-xl transition-all"
        >
          <div
            class="absolute inset-0 z-50 flex items-center justify-center bg-gray-400 bg-opacity-20"
            v-if="loading"
          >
            <span class="loader"></span>
          </div>
          <!-- HEADER -->
          <div
            v-if="hasHeaderSlot || title || closeButton"
            class="sticky flex items-center rounded-t-2xl p-2 md:px-5 md:py-2"
          >
            <div class="flex-grow">
              <slot
                v-if="hasHeaderSlot"
                name="header"
                :open="open"
                :hide="hide"
              />
              <span
                class="text-lg font-semibold sm:text-xl"
                v-else-if="title"
                >{{ title }}</span
              >
            </div>

            <button
              class="inline-flex items-center rounded-full p-2.5 text-center focus:outline-none focus:ring-4 active:opacity-75"
              role="button"
              v-if="closeButton"
              :disabled="!canClose"
              @click="hide()"
            >
              <BIconClose />
            </button>
          </div>

          <div
            id="modalContent"
            class="flex max-h-full flex-grow overflow-auto"
            :class="[
              { 'rounded-b-2xl': !hasFooterSlot },
              {
                'border-t-2': border && (hasHeaderSlot || title || closeButton),
              },
              { 'border-b-2': border && hasFooterSlot },
            ]"
          >
            <slot name="default" :open="open" :hide="show" />
          </div>

          <!-- FOOTER -->
          <div v-if="hasFooterSlot" class="flex rounded-b-2xl">
            <slot name="footer" :open="open" :hide="hide" />
          </div>
        </div>
      </Transition>
    </div>
  </Transition>
</template>

<script lang="ts" setup>
import {
  defineComponent,
  ref,
  PropType,
  watch,
  nextTick,
  computed,
  onUnmounted,
  useSlots,
} from "vue";
import BIconClose from "./icons/BIconClose.vue";

const props = defineProps({
  show: {
    type: Boolean,
    required: false,
  },
  backdropClasses: {
    type: String as PropType<string | "default" | "none">,
    required: false,
    default: () => "default",
  },
  fullscreen: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  canClose: {
    type: Boolean,
    required: false,
    default: () => true,
  },
  closeOnBackgroundClick: {
    type: Boolean,
    required: false,
    default: () => true,
  },
  closeButton: {
    type: Boolean,
    required: false,
    default: () => true,
  },
  title: {
    type: String,
    required: false,
  },
  border: Boolean,
  loading: {
    type: Boolean,
    required: false,
    default: () => false,
  },
});

const emit = defineEmits(["hide", "show", "update:show", "backdrop-click"]);

const slots = useSlots();

defineComponent({
  name: "b-modal",
  inheritAttrs: false,
});

const modalEl = ref(null);

const showModal = ref(props.show == true);

onUnmounted(() => {
  removeEventListener("keydown", onKeyEscDown);
});

watch(
  () => props.show,
  () => (showModal.value = props.show == true)
);

watch(
  () => showModal.value,
  async (newValue) => {
    if (newValue) {
      await nextTick();
      if (modalEl.value) {
        modalEl.value.focus();
      }
      emit("show");
      addEventListener("keydown", onKeyEscDown);
    } else {
      emit("hide");
      removeEventListener("keydown", onKeyEscDown);
    }
  }
);

const open = () => {
  showModal.value = true;
  emit("update:show", true);
};

const hide = () => {
  showModal.value = false;
  emit("update:show", false);
};

const handleBackdropClick = (event) => {
  if (props.closeOnBackgroundClick) {
    showModal.value = false;
    emit("update:show", false);
  }

  emit("backdrop-click", event);
};

const hasHeaderSlot = computed<boolean>(() => {
  return !!slots.header;
});

const hasFooterSlot = computed<boolean>(() => {
  return !!slots.footer;
});

const onKeyEscDown = (event: KeyboardEvent) => {
  if (event && event.key == "Escape") {
    hide();
  }
};
</script>
<style scoped>
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
