<template>
  <BaseIcon :name="name" :size="size" viewBox="0 0 32 32">
    <path class="actif" d="M22.717529,30.491699H5.46167c-1.333008,0-2.417969-1.084473-2.417969-2.417969V10.817871
	c0-1.333496,1.084961-2.417969,2.417969-2.417969h11.748047c0.276367,0,0.5,0.223633,0.5,0.5s-0.223633,0.5-0.5,0.5H5.46167
	c-0.782227,0-1.417969,0.63623-1.417969,1.417969V28.07373c0,0.781738,0.635742,1.417969,1.417969,1.417969h17.255859
	c0.782227,0,1.417969-0.63623,1.417969-1.417969V16.321777c0-0.276367,0.223633-0.5,0.5-0.5s0.5,0.223633,0.5,0.5V28.07373
	C25.135498,29.407227,24.050537,30.491699,22.717529,30.491699z"/>
<path class="actif" d="M28.956299,4.659119L18.384766,4.700073v0.001953c-0.003357-0.000061-0.006165-0.001953-0.009583-0.001953
	c-0.311035,0-0.563171,0.251587-0.563171,0.56189c0,0.308838,0.249939,0.558594,0.558838,0.561035v0.002075l8.819031-0.088806
	L13.575928,19.537598c-0.193359,0.196777-0.191406,0.513184,0.004883,0.707031
	c0.097656,0.096191,0.224609,0.144043,0.351562,0.144043c0.128906,0,0.257812-0.049805,0.356445-0.148926L27.903259,6.437927
	l-0.115601,8.707397c0,0.001404-0.000793,0.002502-0.000793,0.003906c0,0.001221,0.000671,0.002197,0.000671,0.003418
	l0.00116,0.005737c0.005188,0.280884,0.231384,0.507446,0.511963,0.507446c0.275635,0,0.496521-0.219604,0.509155-0.493591
	L28.956299,4.659119z"/>
  </BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import BaseIcon from "./BaseIcon.vue";
import { IconType, SizeType } from "../index";

const name: IconType = "fen_mere";

export default defineComponent({
  name: "b-icon-" + name,
  components: {
    BaseIcon,
  },
  props: {
    size: {
      type: String as PropType<SizeType>,
      default: () => "md",
    },
  },
  setup() {
    return {
      name,
    };
  },
});
</script>
