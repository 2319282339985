import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "inline-flex w-full max-w-160 p-2" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-col items-center"
}
const _hoisted_3 = { class: "text-lg py-2" }
const _hoisted_4 = {
  key: 0,
  class: "inline-flex w-full p-2 space-x-2 justify-between"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "text-lg font-semibold" }

import { PropType } from "vue";
import { ICustomAlert } from "./CustomAlert.d";
import BModal from "@/components/global/BModal.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomAlert',
  props: {
  options: {
    type: Object as PropType<ICustomAlert>,
  },
},
  emits: ["hide"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BModal, {
    show: true,
    style: {"max-height":"60vh"},
    title: __props.options?.title || 'Alerte',
    onHide: _cache[0] || (_cache[0] = () => emit('hide')),
    border: ""
  }, {
    footer: _withCtx(() => [
      (__props.options.buttons)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options.buttons, (btn, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: _normalizeClass(["cursor-pointer w-full text-center rounded-md border-2 p-2 transition-all hover:bg-light", 
            'border-' +
            btn.color +
            ' ' +
            (btn.plain === true
              ? 'bg-' +
                btn.color +
                ' ' +
                (btn.color == 'primary' ? 'text-primary-auto' : 'text-white')
              : 'bg-element')
          ]),
                onClick: ($event: any) => (__props.options.buttons[index].click())
              }, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(btn.text), 1)
              ], 10, _hoisted_5))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default"),
        (__props.options)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(__props.options.text), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }, 8, ["title"]))
}
}

})