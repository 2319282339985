import { IUserIntervenant } from "@/@models/user";
import { IResponse } from "@/@types/api";
import { authService } from "@/api/auth";
import { consultationsService } from "@/api/consultations";
import { isPreviewInUrl, TEMPORARY_isDev } from "@/utils";
import { checkData, isValidStatus } from "@/utils/http";
import { defineStore } from "pinia";
import { ref } from "vue";
import { useToast } from "vue-toastification";

 
export function isIdentifiantValid(identifiant) {
  //si login ne contient que des chiffres et des lettres en majuscule
  const re = /^[A-Z0-9]+$/;
  return re.test(String(identifiant)) && identifiant.length == 6;
}

export enum UserType {
  intervenant = "intervenant",
  user = "user",
}

export enum UserAccess {
  evenements = "evenements",
  consultations = "consultations",
  projets = "projets",
  viewerIfc = "viewer_ifc",
  espaceEntreprise = "espace_entreprise",
}

export type ICurrentRequestError = 0 | 401 | 600;

export const useAuthStore = defineStore("auth", {
  state: () => {
    const type = localStorage.getItem("typeAuth");
    const token = localStorage.getItem("token");
    const login = localStorage.getItem("login");
    const currentRequestError:ICurrentRequestError = 0;
    const rememberMe = Boolean(localStorage.getItem("rememberMe"));
    const societeIndex = parseInt(localStorage.getItem("societeIndex"));

    return {
      currentRequestError,
      login: login ?? "",
      token: token ?? "",
      idMachine: "test",
      rememberMe: rememberMe ?? true,
      societeIndex: societeIndex ?? -1,
      type: type ?? UserType.user,

      user: null as IUserIntervenant,

      access: [] as UserAccess[],
      lastCheckAccess: null,

      error: null,
    };
  },
  getters: {
    canAccessEvenements(state) {
      return false;
      //return state.access.includes(UserAccess.evenements);
    },
    canAccessConsultation(state) {
      return  isPreviewInUrl() || state.access.includes(UserAccess.consultations);
    },
    canAccessProjets(state) {
      return   state.access.includes(UserAccess.projets); 
     },
    canAccessViewerIfc(state) {
      return state.access.includes(UserAccess.viewerIfc);
    },
    canAccessEspaceEntreprise(state) {
      return  isPreviewInUrl() ||state.access.includes(UserAccess.espaceEntreprise);
    },
  },
  actions: {
    setCurrentRequestError(error:ICurrentRequestError){ 
      if(this.currentRequestError != error){
        this.currentRequestError = error;
      }
    },
    async authUserLocal() {
      if ((window as any).$4d) {
        this.clear();
        const { data } = await new Promise<IResponse>((res) =>
          (window as any).$4d.ifc_GetTokenUser((result) => res(result))
        );
        if (!checkData(data)) {
          this.user = null;
          this.error = Error("La récupération de l'utilisateur a échoué.");
          return false;
        }
        this.type = UserType.user;
        this.token = data.token;
        this.user = data.user;
        this.storeAuth();

        return true;
      }
      return false;
    },
    /**
     * Recupere l'utilisateur intervenant lié au token
     * @returns
     */
    async fetchIntervenantUser() {
      const { status, data } = await consultationsService.getIntervenantUser();
      if (!isValidStatus(status) || !data || !data.user) {
        this.user = null;
        this.error = Error("La récupération de l'utilisateur a échoué.");
        return false;
      }
      this.user = data.user;
      return true;
    },
    /**
     * Recupere la liste des acces de l'utilisateur (pour l'intervenant)
     */
    async fetchAccess() {
      this.lastCheckAccess = new Date();
      const { status, data } = await authService.getAccess();
      if (!isValidStatus(status) || !checkData(data)) {
        this.access = [];
        this.error = Error(
          "La récupération des accès de l'utilisateur a échoué."
        );
        return false;
      }
      this.access = data.acces;
      return true;
    },

    /**
     * Authentifie le l'utilisateur et recupere son token
     * @param payload
     */
    async authenticateUser(payload: {
      login: string;
      password: string;
      societe: number;
      rememberMe: boolean;
    }): Promise<boolean> {
      this.clear();

      const { status, data } = await authService.loginUser({
        login: payload.login,
        mdp: payload.password,
        societe: payload.societe,
        idmachine: this.idMachine,
      });

      if (!isValidStatus(status)) {
        this.error = Error("L'authentification a échoué.");
        return false;
      }
      this.type = UserType.user;
      this.token = data.token;
      this.rememberMe = payload.rememberMe;
      this.login = payload.login;
      this.societeIndex = payload.societe;

      this.storeAuth();

      return true;
    },

    /**
     * Authentifie le l'utilisateur et recupere son token
     * @param payload
     */
    async authenticateIntervenant(payload: {
      login: string;
      password: string;
      rememberMe: boolean;
    }) {
      const { status, data } = await authService.loginIntervenant({
        login: payload.login,
        mdp: payload.password,
        societe: parseInt(this.societeIndex),
      });

      const toastification = useToast();
      if (!isValidStatus(status)) {
        toastification.error(
          "L'authentification a échoué. Veuillez vérifier vos informations."
        );
        return false;
      }
      if (data?.multipleContacts) {
        toastification.warning(
          "Plusieurs contacts avec la même adresse email.\nVeuillez utiliser votre identifiant."
        );
        return false;
      }

      this.type = UserType.intervenant;
      this.token = data.token;
      this.rememberMe = payload.rememberMe;
      this.login = payload.login;

      this.storeAuth();

      return true;
    },
    async verifAuthentification(): Promise<boolean> {
      try {
        if (this.type == UserType.intervenant) {
          const { status, data, response }: any =
            await authService.verifAuthentificationIntervenant({
              email: this.login,
              token: this.token,
              societe: parseInt(this.societeIndex),
            });
          if (!isValidStatus(status)) {
            if (response?.data?.error) {
              const toastification = useToast();
              if (
                response.data.error.message.includes(
                  "Plusieurs contacts avec la même adresse email"
                )
              ) {
                toastification.error(
                  response.data.error.message +
                    "\n" +
                    "Veuiilez contacter votre administrateur."
                );
              }
              console.error(response.data.error);
            }
          }

          return isValidStatus(status) && !!data.token;
        } else {
          const { data } = await authService.verifAuthentificationUser({
            idmachine: this.idMachine,
            login: this.login,
            societe: parseInt(this.societeIndex),
            token: this.token,
          });

          return data.success;
        }
      } catch (err) {
        console.error(err);
        return false;
      }
    },
    async resetPassordIntervenant(payload: { societe: number; email: string }) {
      const { status, data } = await authService.resetPasswordIntervenant(
        payload
      );
      const toastification = useToast();

      if (data?.multipleContacts) {
        toastification.warning(
          "Plusieurs contacts avec la même adresse email.\nVeuillez utiliser votre identifiant."
        );
        return false;
      }
      if (!isValidStatus(status) || !checkData(data)) {
        toastification.error(
          "La demande de réinitialisation du mot de passe a échoué."
        );
        return false;
      }
      return true;
    },

    /**
     * Efface les données et vide le store
     */
    logout() {
      // TODO: clear store
      this.clear();
    },
    /**
     * efface les données
     */
    clear() {
      this.token = "";
      this.rememberMe = true;
      this.login = "";
      this.societeIndex = -1;
      this.type = UserType.intervenant;
      this.access = [];
      this.lastCheckAccess = null;
      this.storeAuth();
    },
    /**
     * Enregistre les informations dans le localStorage
     */
    storeAuth() {
      localStorage.setItem("token", this.token);
      localStorage.setItem("typeAuth", this.type);
      localStorage.setItem("rememberMe", this.rememberMe);
      localStorage.setItem("login", this.login);
      localStorage.setItem("societeIndex", this.societeIndex);
    },

    /**
     * Enregistre les informations dans le localStorage
     */
    async postUserIntervenant(payload) {
      const { status, data } = await consultationsService.postContact(
        this.user.index,
        payload
      );
      const toastification = useToast();

      if (!isValidStatus(status)) {
        toastification?.error("La modification de l'utilisateur a échoué.");
        return false;
      }
      for (const key in this.user)
        this.user[key] = payload[key] ? payload[key] : this.user[key];
      toastification?.success(
        "Modification des données de l'utilisateur réussi."
      );
      return true;
    },

    /**
     * Modifie le mot de passe de l'utilisateur de l'intervenat
     */
    async changePasswordUserIntervenant(payload: {
      password: string;
      newPassword: string;
    }) {
      const toastification = useToast();
      const { status, data } = await authService.changePasswordIntervenant({
        index: this.user.index,
        password: payload.password,
        newPassword: payload.newPassword,
      });
      if (!isValidStatus(status) || !checkData(data)) {
        toastification?.error("La modification du mot de passe a échoué.");
        return false;
      }
      toastification?.success("Modification du mot de passe réussi.");
      return true;
    },

    canAccess(route) {
      if (!route?.meta?.access || route?.meta?.access.length == 0) {
        return true;
      }

      if (route?.meta?.access?.length > 0) {
        for (const _access of this.access as UserAccess[]) {
          if (route.meta.access.includes(_access)) {
            return true;
          }
        }
      }
      return false;
    },
  },
});
