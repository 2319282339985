import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

import useLayout from "@/pages/bureau/composables/useLayout";
import CustomAlert from "@/components/CustomAlert.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomAlertPopup',
  setup(__props) {

const { setCustomAlertPopup, customAlertPopup } = useLayout();

function hide() {
  setCustomAlertPopup(undefined);
}

return (_ctx: any,_cache: any) => {
  return (_unref(customAlertPopup))
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "absolute left-0 top-0 z-99999 h-full w-full select-none",
        onContextmenu: _withModifiers(() => {}, ["prevent"]),
        onClick: hide
      }, [
        (_unref(customAlertPopup))
          ? (_openBlock(), _createBlock(CustomAlert, {
              key: 0,
              onHide: _cache[0] || (_cache[0] = ($event: any) => (_unref(setCustomAlertPopup)(null))),
              options: _unref(customAlertPopup)
            }, null, 8, ["options"]))
          : _createCommentVNode("", true)
      ], 32))
    : _createCommentVNode("", true)
}
}

})