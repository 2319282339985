import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

import Layout from "../layout/index.vue";
import { useAuthStore } from "@/stores";
import { evenementsRoutesName } from "./modules/evenements";
import { consultationRoutesName } from "./modules/consultation";
import { projetsRoutesName } from "./modules/projets";
import { entreprisesRoutesName } from "./modules/espace-entreprise";

export const BASE_ROUTE = "base";

const staticRoutes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "societe-select",
    component: () => import("@/pages/ao/views/Societe.vue"),
  },
  {
    path: "/:index_societe/auth",
    name: "auth",
    redirect: {
      name: "login",
    },
    component: () => import("@/pages/ao/views/auth/BaseAuth.vue"),
    children: [
      {
        path: "login",
        name: "login",
        component: () => import("@/pages/ao/views/auth/subviews/Login.vue"),
      },
      {
        path: "visitor",
        name: "visitor",
        component: () => import("@/views/BlankPage.vue"),
      },
      {
        path: "forgot-password",
        name: "forgot-password",
        component: () =>
          import("@/pages/ao/views/auth/subviews/ForgotPassword.vue"),
      },
      {
        path: "disable",
        name: "disable",
        component: () => import("@/pages/ao/views/auth/subviews/Desactive.vue"),
      },
    ],
  },
  {
    name: BASE_ROUTE,
    path: "/:index_societe",
    component: Layout,
    redirect: {
      name: "home",
    },
    children: [
      {
        path: "accueil",
        name: "home",
        component: () => import("@/pages/ao/views/home/HomeView.vue"),
      },
      // Pour les routes dynamiques
      {
        path: ":pathMatch(.*)*",
        redirect: (r) => {
          return {
            name: BASE_ROUTE,
            query: {
              redirect: r.fullPath,
            },
          };
        },
      },
    ],
  },
];

if (process.env.NODE_ENV != "production") {
  staticRoutes.push({
    name: "test",
    path: "/test",
    component: () => import("@/pages/ao/views/auth/AuthView.vue"),
  });
}

const router = createRouter({
  history: createWebHashHistory(),
  routes: staticRoutes,
});

router.beforeEach(async (to, from, next) => {
  await performAsyncChecks(to, from);
  setWebTitle(to.name as string);
  next();
});

let oldHash = "";

async function performAsyncChecks(to, from) {
  const pathArray = to.fullPath.split("/");
  const authStore = useAuthStore();
  if (pathArray.length > 1) {
    if (oldHash == "") {
      oldHash = pathArray[1];
    } else if (oldHash != pathArray[1]) {
      oldHash = pathArray[1];
      window.location.reload();
    }
  }
  if (!authStore.lastCheckAccess) {
    if (from.path == "/" && to.fullPath.includes("?redirect")) {
      if (
        to.fullPath.includes("consultations") &&
        to.fullPath.includes("bordereaux")
      ) {
        const indexConsultations = pathArray.findIndex(
          (item) => item == "consultations"
        );

        (async () => {
          if (pathArray.length < indexConsultations + 7) {
            return;
          }
          await new Promise((res) => setTimeout(res, 500));
          try {
            router.push({
              name: "bordereaux",
              params: {
                uid_consultation: pathArray[indexConsultations + 1],
                uid_lot: pathArray[indexConsultations + 3],
                uid_offre: pathArray[indexConsultations + 5],
              },
            });
          } catch (error) {
            console.error("router dev reload error", error);
          }
        })();
      }
    }
  }
}

function isEvenementsRoute(routeName: string) {
  return evenementsRoutesName.includes(routeName as string);
}
function isConsultationRoute(routeName: string) {
  return consultationRoutesName.includes(routeName as string);
}
function isProjetRoute(routeName: string) {
  return projetsRoutesName.includes(routeName as string);
}
function isEntrepriseRoute(routeName: string) {
  return entreprisesRoutesName.includes(routeName as string);
}

function setWebTitle(routeName: string) {
  if (isEvenementsRoute(routeName)) {
    document.title = "BIMoffice - Evenements";
  } else if (isConsultationRoute(routeName)) {
    document.title = "BIMoffice - Appels d'offres";
  } else if (isProjetRoute(routeName)) {
    document.title = "BIMoffice - Projets";
  } else if (isEntrepriseRoute(routeName)) {
    document.title = "BIMoffice - Espace personnel";
  } else {
    document.title = "BIMoffice - Accueil";
  }
}

export default router;
