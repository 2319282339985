<template>
  <BDropdown arrow stopPropagation>
    <template v-slot:trigger="{ toggle }">
      <button
        @click="(!isPreviewInUrl()) && toggle()"
        class="inline-flex items-center rounded-full border-primary border-2   text-center text-sm text-white focus:outline-none focus:ring-4 active:opacity-75"
      >
      <BIcon
      v-if="isPreviewInUrl()"
        name="user"
        size="auto"
        class="h-10 w-10 overflow-hidden rounded-full text-gray-600"
      />
       <div
        v-else
       class="h-10 w-10 overflow-hidden rounded-full" 
      :class="intervenant?.image? '':' p-0.5'"
      >
           <img
           v-if="intervenant?.image"
            :src=" `data:image/png;base64,${intervenant?.image}`"
            alt="avatar"
            class="h-full w-full object-contain "
          />
          <div v-else class="h-full text-xl font-semibold w-full  flex items-center justify-center">
            {{intervenantInitials}}
          </div>


        </div>
        <span class="sr-only">Open user menu</span>
      </button>
    </template>

    <ul
      aria-labelledby="dropdownUser"
      class="w-60 space-y-2 rounded-xl border bg-menu p-1 shadow-2xl dark:border-dark"
    >
      <li class="px-2">
        <div class="p-2" v-if="user">
          Bienvenue<br />
          
          <span class="  font-bold inline-flex items-center gap-x-1"><User/>
            {{
            `${intervenant.nom}`
          }}</span>
          <br />
          <span class=" font-semibold inline-flex items-center gap-x-1">
            <Contact/>
            {{
            `${user.prenom} ${user.nom}`
          }}</span>
          <br />
          <span class=" text-sm inline-flex items-center gap-x-1">
            <Mail/>  {{ user.email }} </span> 
        </div>
      </li>
      <li class="px-2">
        <b-input-switch v-model="darkMode" label="Mode sombre" />
      </li>

      <!-- DOCUMENTATION -->
      <li class="mt-3 flex flex-col px-2">
        <span>Besoin d'aide ?</span>
        <a
          class="text-primary underline hover:opacity-75"
          href="https://docs.google.com/document/d/1NHstnNHCB1rowIM4hXCu70i6z8Agdxo50JG58TwZkS0/edit"
          target="_blank"
          >Documentation</a
        >
      </li>

      <li>
        <BBtn variant="danger" size="full" @click="logout()">Déconnexion</BBtn>
      </li>
    </ul>
  </BDropdown>
</template>

<script lang="ts" setup>
import { createPopper } from "@/plugins/popper";
import { useAuthStore } from "@/stores";
import { useAOStore } from "@/pages/ao/store";
import { storeToRefs } from "pinia";
import { defineComponent, nextTick, ref, watch,computed } from "vue";
import { useRouter } from "vue-router";
import { BInputSwitch, BBtn, BDropdown ,BIcon} from "@/components/global";
import { User,Mail,Contact } from "@/components/global/icons";
import { onMounted } from "vue";
import { useEntrepriseStore } from "@/pages/ao/store";
import { isPreviewInUrl } from "@/utils";

defineComponent({
  name: "account-popover",
});

let popperInstance = null;

const show = ref(false);

const userTriggerEl = ref();
const userTargetEl = ref();

const aoStore = useAOStore();
const authStore = useAuthStore();
const entrepriseStore = useEntrepriseStore();

const { darkMode } = storeToRefs(aoStore);
const { user } = storeToRefs(authStore);
const { intervenant } = storeToRefs(entrepriseStore);

const router = useRouter();

const logout = () => {
  authStore.logout();
  router.push({ name: "login" });
};
onMounted(async () => {
  await entrepriseStore.fetchIntervenant();
});

watch(
  () => show.value,
  (newValue) => {
    if (newValue) {
      nextTick(() => {
        popperInstance = createPopper(userTriggerEl.value, userTargetEl.value, {
          placement: "bottom",
        });
      });
    } else if (popperInstance) {
      setTimeout(() => popperInstance.destroy(), 250);
    }
  }
);

const intervenantInitials = computed(() => {
  const nom = intervenant.value?.nom || "";
  let toreturn = ""; 
  
  //split " "
  toreturn =  nom.split(" ").map((word) => word[0]).join("");
  
  //si toreturn.length < 2 alors on vas remplacer le camelCase par un espace
  if(toreturn.length < 2){
    toreturn =  nom.replace(/([a-z])([A-Z])/g, '$1 $2').split(" ").map((word) => word[0]).join("");
  }


  return toreturn;
});

</script>
<style></style>
@/pages/ao/stores
