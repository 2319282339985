<template>
  <BaseIcon :name="name" :size="size" viewBox="0 0 20 20">
    
    <path class="actif" d="M7.9217882,3.4331186l1.9343424-1.933985c0.0668297-0.0686896,0.2171965-0.0672972,0.2840261-0.0004641
	l1.9343424,1.9344491c0.0928192,0.092824,0.2144117,0.139236,0.3360043,0.139236s0.243185-0.046412,0.3360033-0.139236
	c0.1856375-0.1856477,0.1856375-0.486397,0-0.6720445l-1.9343424-1.9339852
	C10.3787003,0.3922091,9.6212997,0.3898885,9.184123,0.8266248L7.2497802,2.7610741
	c-0.1856375,0.1856475-0.1856375,0.4863968,0,0.6720445S7.7361503,3.6187663,7.9217882,3.4331186z"/>
<path class="actif" d="M19.1621399,9.1868057l-1.9343433-1.9344497c-0.1856365-0.1856475-0.4863701-0.1856475-0.6720085,0
	c-0.1856365,0.185648-0.1856365,0.4863973,0,0.6720448l1.9343433,1.9349132
	c0.0519791,0.051053,0.0594044,0.1109247,0.0594044,0.1415567c0,0.0310955-0.0074253,0.0909672-0.0594044,0.1424847
	l-1.9343433,1.9339848c-0.1856365,0.185648-0.1856365,0.4863968,0,0.6720448
	c0.0928192,0.092824,0.2144127,0.1392355,0.3360043,0.1392355c0.1215935,0,0.243185-0.0464115,0.3360043-0.1392355
	l1.9343433-1.9335203C19.3793354,10.5981922,19.5,10.3090458,19.5,10.0008707
	C19.5,9.6931591,19.3793354,9.4040136,19.1621399,9.1868057z"/>
<path class="actif" d="M10.0018568,17.285223c0.2626762,0,0.4752312-0.2125664,0.4752312-0.4747944v-6.3343h6.3339529
	c0.2626762,0,0.4752312-0.2125664,0.4752312-0.4752579s-0.2125549-0.4752579-0.4752312-0.4752579h-6.3376656V3.1783171
	c0-0.2626915-0.2125549-0.4752581-0.4752321-0.4752581c-0.2626762,0-0.4752312,0.2125666-0.4752312,0.4752581v6.3343H3.1889594
	c-0.262677,0-0.4752319,0.2125664-0.4752319,0.4752579s0.2125549,0.4752588,0.4752319,0.4752588h6.3376656v6.3472948
	C9.5266247,17.0726566,9.7391796,17.285223,10.0018568,17.285223z"/>
<path class="actif" d="M12.0782118,16.5556278l-1.9343424,1.9344482c-0.0519791,0.051054-0.1113825,0.0594082-0.1420126,0.0594082
	h-0.0009289c-0.0306301,0-0.0900335-0.0083542-0.1410847-0.0594082l-1.9343424-1.9344482
	c-0.1856375-0.185648-0.4863706-0.185648-0.672008,0s-0.1856375,0.4863968,0,0.6720448l1.9334149,1.9335213
	C9.4041033,19.3793297,9.69277,19.4990711,10.0009279,19.5c0,0,0,0,0.0009289,0c0.30723,0,0.5958958-0.1197433,0.8140202-0.3378792
	l1.9343433-1.9344482c0.1856375-0.185648,0.1856375-0.4863968,0-0.6720448
	C12.5645819,16.3699799,12.2638493,16.3699799,12.0782118,16.5556278z"/>
<path class="actif" d="M1.5098681,10.1294317c-0.0519786-0.051053-0.059404-0.1104603-0.059404-0.1415567
	c0-0.0310955,0.0074254-0.0905027,0.059404-0.1420202L3.444211,7.9114056c0.1856375-0.1856475,0.1856375-0.4863968,0-0.6720448
	c-0.1856375-0.1856475-0.4863703-0.1856475-0.6720078,0L0.8378603,9.1733456C0.6206644,9.3910179,0.5,9.6801643,0.5,9.987875
	c0,0.3077116,0.1206644,0.596858,0.3378603,0.8140659l1.9343429,1.9344492
	c0.0928187,0.092823,0.2144113,0.1392355,0.336004,0.1392355c0.1215925,0,0.243185-0.0464125,0.3360038-0.1392355
	c0.1856375-0.185648,0.1856375-0.4863977,0-0.6720448L1.5098681,10.1294317z"/>
  </BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import BaseIcon from "./BaseIcon.vue";
import { IconType, SizeType } from "../index";

const name: IconType = "move";

export default defineComponent({
  name: "b-icon-" + name,
  components: {
    BaseIcon,
  },
  props: {
    size: {
      type: String as PropType<SizeType>,
      default: () => "md",
    },
  },
  setup() {
    return {
      name,
    };
  },
});
</script>
