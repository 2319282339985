<template>
  <BModal
    :show="true"
    style="max-height: 60vh"
    :title="options?.title || 'Alerte'"
    @hide="() => emit('hide')"
    border
  >
    <div class="inline-flex w-full max-w-160 p-2">
      <slot />
      <div v-if="options" class="flex flex-col items-center">
        <span class="text-lg py-2">{{ options.text }}</span>
      </div>
    </div>
    <template #footer>
      <div class="inline-flex w-full p-2 space-x-2 justify-between" v-if="options.buttons">
        <div
          v-for="(btn, index) in options.buttons"
          :key="index"
          class="  cursor-pointer w-full text-center rounded-md border-2 p-2 transition-all hover:bg-light"
          :class="
            'border-' +
            btn.color +
            ' ' +
            (btn.plain === true
              ? 'bg-' +
                btn.color +
                ' ' +
                (btn.color == 'primary' ? 'text-primary-auto' : 'text-white')
              : 'bg-element')
          "
          @click="options.buttons[index].click()"
        >
          <span class="text-lg font-semibold">{{ btn.text }}</span>
        </div>
      </div>
    </template>
  </BModal>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { ICustomAlert } from "./CustomAlert.d";
import BModal from "@/components/global/BModal.vue";

const props = defineProps({
  options: {
    type: Object as PropType<ICustomAlert>,
  },
});
const emit = defineEmits(["hide"]);
</script>
