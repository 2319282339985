import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, normalizeClass as _normalizeClass, mergeProps as _mergeProps, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  tabindex: "-1",
  "aria-hidden": "true",
  class: "fixed inset-0 z-[69] flex backdrop-blur-sm"
}
const _hoisted_2 = {
  key: 0,
  class: "absolute inset-0 z-50 flex items-center justify-center bg-gray-400 bg-opacity-20"
}
const _hoisted_3 = {
  key: 1,
  class: "sticky flex items-center rounded-t-2xl p-2 md:px-5 md:py-2"
}
const _hoisted_4 = { class: "flex-grow" }
const _hoisted_5 = {
  key: 1,
  class: "text-lg font-semibold sm:text-xl"
}
const _hoisted_6 = ["disabled"]
const _hoisted_7 = {
  key: 2,
  class: "flex rounded-b-2xl"
}

import {
  defineComponent,
  ref,
  PropType,
  watch,
  nextTick,
  computed,
  onUnmounted,
  useSlots,
} from "vue";
import BIconClose from "./icons/BIconClose.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'BModal',
  props: {
  show: {
    type: Boolean,
    required: false,
  },
  backdropClasses: {
    type: String as PropType<string | "default" | "none">,
    required: false,
    default: () => "default",
  },
  fullscreen: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  canClose: {
    type: Boolean,
    required: false,
    default: () => true,
  },
  closeOnBackgroundClick: {
    type: Boolean,
    required: false,
    default: () => true,
  },
  closeButton: {
    type: Boolean,
    required: false,
    default: () => true,
  },
  title: {
    type: String,
    required: false,
  },
  border: Boolean,
  loading: {
    type: Boolean,
    required: false,
    default: () => false,
  },
},
  emits: ["hide", "show", "update:show", "backdrop-click"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const slots = useSlots();

defineComponent({
  name: "b-modal",
  inheritAttrs: false,
});

const modalEl = ref(null);

const showModal = ref(props.show == true);

onUnmounted(() => {
  removeEventListener("keydown", onKeyEscDown);
});

watch(
  () => props.show,
  () => (showModal.value = props.show == true)
);

watch(
  () => showModal.value,
  async (newValue) => {
    if (newValue) {
      await nextTick();
      if (modalEl.value) {
        modalEl.value.focus();
      }
      emit("show");
      addEventListener("keydown", onKeyEscDown);
    } else {
      emit("hide");
      removeEventListener("keydown", onKeyEscDown);
    }
  }
);

const open = () => {
  showModal.value = true;
  emit("update:show", true);
};

const hide = () => {
  showModal.value = false;
  emit("update:show", false);
};

const handleBackdropClick = (event) => {
  if (props.closeOnBackgroundClick) {
    showModal.value = false;
    emit("update:show", false);
  }

  emit("backdrop-click", event);
};

const hasHeaderSlot = computed<boolean>(() => {
  return !!slots.header;
});

const hasFooterSlot = computed<boolean>(() => {
  return !!slots.footer;
});

const onKeyEscDown = (event: KeyboardEvent) => {
  if (event && event.key == "Escape") {
    hide();
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _renderSlot(_ctx.$slots, "action", {
      open: open,
      hide: hide
    }),
    _createVNode(_Transition, {
      "enter-active-class": "duration-100 ease-in",
      "enter-from-class": "opacity-0",
      "enter-to-class": "opacity-100",
      "leave-active-class": "duration-200 ease-out",
      "leave-from-class": "opacity-100",
      "leave-to-class": "opacity-0"
    }, {
      default: _withCtx(() => [
        (showModal.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (handleBackdropClick($event))),
                onContextmenu: _withModifiers(() => {}, ["prevent"]),
                oncontextmenu: "return false",
                tabindex: "-1",
                class: "fixed inset-0 m-0 p-0 opacity-60"
              }, null, 32),
              _createVNode(_Transition, {
                appear: "",
                "enter-active-class": "duration-200 ease-out",
                "enter-from-class": "scale-95 translate-y-20",
                "enter-to-class": "scale-100 translate-y-0"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _mergeProps({
                    ref_key: "modalEl",
                    ref: modalEl,
                    tabindex: "-1"
                  }, _ctx.$attrs, {
                    class: [[
            { ' max-w-2xl rounded-lg md:h-auto': !__props.fullscreen },
            { ' h-full w-full rounded-md': __props.fullscreen },
            { 'border-2': __props.border },
          ], "relative z-70 m-auto box-border flex max-h-full max-w-full flex-col overflow-hidden rounded-2xl bg-element text-left text-base opacity-100 shadow-xl transition-all"]
                  }), [
                    (__props.loading)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[2] || (_cache[2] = [
                          _createElementVNode("span", { class: "loader" }, null, -1)
                        ])))
                      : _createCommentVNode("", true),
                    (hasHeaderSlot.value || __props.title || __props.closeButton)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          _createElementVNode("div", _hoisted_4, [
                            (hasHeaderSlot.value)
                              ? _renderSlot(_ctx.$slots, "header", {
                                  key: 0,
                                  open: open,
                                  hide: hide
                                })
                              : (__props.title)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(__props.title), 1))
                                : _createCommentVNode("", true)
                          ]),
                          (__props.closeButton)
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 0,
                                class: "inline-flex items-center rounded-full p-2.5 text-center focus:outline-none focus:ring-4 active:opacity-75",
                                role: "button",
                                disabled: !__props.canClose,
                                onClick: _cache[1] || (_cache[1] = ($event: any) => (hide()))
                              }, [
                                _createVNode(BIconClose)
                              ], 8, _hoisted_6))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", {
                      id: "modalContent",
                      class: _normalizeClass(["flex max-h-full flex-grow overflow-auto", [
              { 'rounded-b-2xl': !hasFooterSlot.value },
              {
                'border-t-2': __props.border && (hasHeaderSlot.value || __props.title || __props.closeButton),
              },
              { 'border-b-2': __props.border && hasFooterSlot.value },
            ]])
                    }, [
                      _renderSlot(_ctx.$slots, "default", {
                        open: open,
                        hide: __props.show
                      })
                    ], 2),
                    (hasFooterSlot.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          _renderSlot(_ctx.$slots, "footer", {
                            open: open,
                            hide: hide
                          })
                        ]))
                      : _createCommentVNode("", true)
                  ], 16)
                ]),
                _: 3
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ], 64))
}
}

})