import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-1" }

import FRDatePicker from "@/components/global/FRDatePicker.vue";
import { PropType, WritableComputedRef, computed, watch, ref } from "vue";
import dayjs from "dayjs";
import { onMounted } from "vue";
import { deepCopy } from "@/utils";


export default /*@__PURE__*/_defineComponent({
  __name: 'BCustomDatePicker',
  props: {
  modelValue: Array as PropType<string[]>,

  typeDate: {
    type: String as PropType<"day" | "month" | "week" | "year">,
    default: "day",
  },
  noBorder: Boolean,
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const model: WritableComputedRef<string[]> = computed({
  get() {
    return props.modelValue;
  },
  set(newValue): void {
    emit("update:modelValue", newValue);
  },
});

const dayDate = ref(dayjs().format("YYYY-MM-DD"));
const weekDate = ref<string[]>([]);
const monthDate = ref<{
  month: number;
  year: number;
}>({
  month: dayjs().month(),
  year: dayjs().year(),
});
const yearDate = ref(dayjs().format("YYYY"));

/**
 *
 * @param val = ["YYYY-MM-DD", "YYYY-MM-DD"]
 */
function setWeekDate(val) {
  if (val.length == 2 && typeof val[1] == "string") {
    const firstDay = dayjs(val[0]).format("YYYY-MM-DD");
    const lastDay = dayjs(val[1]).format("YYYY-MM-DD");
    model.value = [firstDay, lastDay];
    verifTimestamp.value = new Date().getTime();
  } else if (val.length == 2 && typeof val[1] == "object") {
    const firstDay = dayjs(val[0]).format("YYYY-MM-DD");
    const lastDay = dayjs(val[1]).format("YYYY-MM-DD");
    model.value = [firstDay, lastDay];
    verifTimestamp.value = new Date().getTime();
  } else if (val?.[1]?.length == 2) {
    const firstDay = dayjs(val[1][0]).format("YYYY-MM-DD");
    const lastDay = dayjs(val[1][0]).add(6, "day").format("YYYY-MM-DD");
    model.value = [firstDay, lastDay];
    verifTimestamp.value = new Date().getTime();
  }
}
watch(weekDate, (val) => {
  setWeekDate(val);
});
/**
 *
 * @param val = "YYYY-MM-DD"
 */
function setDayDate(val) {
  const firstDay = dayjs(val).format("YYYY-MM-DD");
  model.value = [firstDay, firstDay];
  verifTimestamp.value = new Date().getTime();
}
watch(dayDate, (val) => {
  setDayDate(val);
});

/**
 *
 * @param val = "YYYY-MM-DD"
 */
function setMonthDate(val) {
  const day = 15;
  const month = dayjs(val).month() + 1;
  const year = dayjs(val).year();
  //model.value = dayjs(`${year}-${month}-${day}`).format("YYYY-MM-DD");
  const firstDay = dayjs(`${year}-${month}-01`).format("YYYY-MM-DD");
  const lastDay = dayjs(`${year}-${month}-01`)
    .endOf("month")
    .format("YYYY-MM-DD");
  model.value = [firstDay, lastDay];
  verifTimestamp.value = new Date().getTime();
}
watch(monthDate, (val) => {
  setMonthDate(dayjs(`${val.year}-${val.month + 1}-01`).format("YYYY-MM-DD"));
});

/**
 *
 * @param val = "YYYY-MM-DD"
 */
function setYearDate(val) {
  const year = dayjs(val).year();
  const firstDay = dayjs(`${year}-01-01`).format("YYYY-MM-DD");
  const lastDay = dayjs(`${year}-12-31`).format("YYYY-MM-DD");
  model.value = [firstDay, lastDay];
  verifTimestamp.value = new Date().getTime();
}

watch(yearDate, (val) => {
  setYearDate(dayjs(`${val}-01-01`).format("YYYY-MM-DD"));
});


const verifTimestamp = ref(new Date().getTime());
watch(
  () => props.modelValue,
  (_val) => {
    if (verifTimestamp.value + 400 > new Date().getTime()) {
      return;
    }
    const val = deepCopy(_val) as string[];
    if (props.typeDate == "day") {
      dayDate.value = val[0];
    } else if (props.typeDate == "week") {
      weekDate.value = [
        dayjs(val[1]).subtract(6, "day").format("YYYY-MM-DD"),
        dayjs(val[1]).format("YYYY-MM-DD"),
      ];
    } else if (props.typeDate == "month") {
      monthDate.value = {
        month: dayjs(val[0]).month(),
        year: dayjs(val[0]).year(),
      };
    } else if (props.typeDate == "year") {
      yearDate.value = dayjs(val[0]).format("YYYY");
    }
  },
  { deep: true }
);

onMounted(() => {
  let modelCopy = deepCopy(model.value);
  if (model.value[0] == undefined || model.value[1] == undefined) {
    modelCopy = [dayjs().format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")];
  }

  switch (props.typeDate) {
    case "day":
      dayDate.value = modelCopy[0];
      break;
    case "week": {
      const lastDayOfWeek = dayjs(modelCopy[1])
        .endOf("week")
        .format("YYYY-MM-DD");
      const firstDayOfWeek = dayjs(modelCopy[0])
        .startOf("week")
        .format("YYYY-MM-DD");
      weekDate.value = [firstDayOfWeek, lastDayOfWeek];
      break;
    }
    case "month":
      monthDate.value = {
        month: dayjs(modelCopy[0]).month(),
        year: dayjs(modelCopy[0]).year(),
      };
      break;
    case "year":
      yearDate.value = dayjs(modelCopy[0]).format("YYYY");
      break;
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.typeDate == 'day')
      ? (_openBlock(), _createBlock(FRDatePicker, {
          key: 0,
          typeDate: __props.typeDate,
          class: "flex-1",
          modelValue: dayDate.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((dayDate).value = $event)),
          enableTimePicker: false,
          format: "dd/MM/yyyy",
          clearable: false
        }, null, 8, ["typeDate", "modelValue"]))
      : _createCommentVNode("", true),
    (__props.typeDate == 'month')
      ? (_openBlock(), _createBlock(FRDatePicker, {
          key: 1,
          typeDate: __props.typeDate,
          class: "flex-1",
          modelValue: monthDate.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((monthDate).value = $event)),
          enableTimePicker: false,
          "month-picker": "",
          format: "MM/yyyy",
          clearable: false
        }, null, 8, ["typeDate", "modelValue"]))
      : _createCommentVNode("", true),
    (__props.typeDate == 'week')
      ? (_openBlock(), _createBlock(FRDatePicker, {
          key: 2,
          typeDate: __props.typeDate,
          class: "flex-1",
          modelValue: weekDate.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((weekDate).value = $event)),
          enableTimePicker: false,
          "week-picker": "",
          format: "dd/MM/yyyy",
          clearable: false
        }, null, 8, ["typeDate", "modelValue"]))
      : _createCommentVNode("", true),
    (__props.typeDate == 'year')
      ? (_openBlock(), _createBlock(FRDatePicker, {
          key: 3,
          typeDate: __props.typeDate,
          class: "flex-1",
          modelValue: yearDate.value,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((yearDate).value = $event)),
          enableTimePicker: false,
          "year-picker": "",
          format: "yyyy",
          clearable: false
        }, null, 8, ["typeDate", "modelValue"]))
      : _createCommentVNode("", true)
  ]))
}
}

})