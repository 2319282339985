import { isMobile } from "@/utils";
import { setStyleParams } from "@/utils/theme";

export function setCustomStyle() {
  //is mobile

 

  /*
  --font-size-xs: 0.625rem;
  --font-size-sm: 0.75rem;
  --font-size-md: 0.8125rem;
  --font-size-base: 0.875rem;
  --font-size-lg: 1rem;
  --font-size-xl: 1.125rem;
  --font-size-2xl: 1.25rem;
  --font-size-3xl: 1.5rem;
  --font-size-4xl: 2rem;
  --font-size-5xl: 2.25rem;
  */

  if (isMobile()) {
    setStyleParams("--font-size-xs", "0.75rem");
    setStyleParams("--font-size-sm", "0.8125rem");
    setStyleParams("--font-size-md", "0.875rem");
    setStyleParams("--font-size-base", "1rem");
    setStyleParams("--font-size-lg", "1.125rem");
    setStyleParams("--font-size-xl", "1.25rem");
    setStyleParams("--font-size-2xl", "1.5rem");
    setStyleParams("--font-size-3xl", "2rem");
    setStyleParams("--font-size-4xl", "2.25rem");
    setStyleParams("--font-size-5xl", "2.5rem");
  }
}
 