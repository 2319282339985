import {
  IPlan,
  IProjet,
  IProjetDossier,
  IProjetEntity,
  IProjetFichier,
  IProjetIntervenant,
  IProjetNote,
  IProjetPartial,
  ProjetFilter,
} from "@/pages/bureau/@models/projet.model";
import { IPagination } from "@/pages/bureau/@types/api";
import axios from "./index";
import { IContact } from "@/pages/bureau/@models/contacts.model";
import {
  IProjetParticipant,
  IProjetParticipantDetail,
} from "@/pages/bureau/store/class/projets.class"; 
import { IDocument } from "@/pages/bureau/components/modals/DocumentsInput";

const BASE_ENDPOINT = "BUREAU";

export const projectsService = {
  /**
   * Recupere tous les projets
   */
  getProjets() {
    return axios.get<{
      projets: IProjetPartial[];
      /*                 requestInfo: {
                                    count: number,
                                    pagined: boolean,
                                    pageCurrent: number,
                                    pageCount: number,
                                    pageSize: number,
                                    elementPartial: boolean
                                } */
    }>(`${BASE_ENDPOINT}/PROJETS`);
  },

  /**
   * Recupere un projet
   */
  getProjet(uidProjet: string) {
    return axios.get<{
      projet: IProjet;
      /*                 requestInfo: {
                                    count: number,
                                    pagined: boolean,
                                    pageCurrent: number,
                                    pageCount: number,
                                    pageSize: number,
                                    elementPartial: boolean
                                } */
    }>(`${BASE_ENDPOINT}/PROJET/${uidProjet}`);
  },

  getProjetsSession() {
    return axios.get(`${BASE_ENDPOINT}/PROJETS/SESSION`);
  },

  /**
   * Recupere un projet
   */
  updateProjet(
    uidProjet: string,
    payload: Omit<IProjetEntity, "uid" | "index" | "code">
  ) {
    return axios.post(`${BASE_ENDPOINT}/PROJET/${uidProjet}`, payload);
  },

  createProjetNotes({
    uid_projet,
    note,
  }: {
    uid_projet: string;
    note: string;
  }) {
    return axios.put(`${BASE_ENDPOINT}/PROJET/${uid_projet}/NOTE`, {
      note: note,
    });
  },
  updateProjetNote({
    uid_projet,
    uid_note,
    note,
  }: {
    uid_projet: string;
    uid_note: string;
    note: IProjetNote;
  }) {
    return axios.post(
      `${BASE_ENDPOINT}/PROJET/${uid_projet}/NOTE/${uid_note}`,
      note
    );
  },

  deleteProjetNote(uid_projet: string, uid_note: string) {
    return axios.delete(
      `${BASE_ENDPOINT}/PROJET/${uid_projet}/NOTE/${uid_note}`
    );
  },

  createIntervenant(uidProjet, payload) {
    return axios.put(
      `${BASE_ENDPOINT}/PROJET/${uidProjet}/INTERVENANT`,
      payload
    );
  },

  updateIntervenant(uidProjet, indexInter, payload) {
    return axios.post(
      `${BASE_ENDPOINT}/PROJET/${uidProjet}/INTERVENANT/${indexInter}`,
      payload
    );
  },

  getParticipants(uidProjet: string, filters) {
    return axios.post<{ participants: IProjetParticipant[] }>(
      `${BASE_ENDPOINT}/PROJET/${uidProjet}/PARTICIPANTS`,
      filters
    );
  },
  getParticipant(uidProjet: string, indexParticipant: number) {
    return axios.get<IProjetParticipantDetail>(
      `${BASE_ENDPOINT}/PROJET/${uidProjet}/PARTICIPANT/${indexParticipant}`
    );
  },
  updateParticipant(uidProjet: string, indexParticipant: number, payload) {
    return axios.post(
      `${BASE_ENDPOINT}/PROJET/${uidProjet}/PARTICIPANT/${indexParticipant}`,
      payload
    );
  },

  createParticipant(uidProjet: string, indexUser: number) {
    return axios.put(
      `${BASE_ENDPOINT}/PROJET/${uidProjet}/PARTICIPANT/${indexUser}`
    );
  },

  getSearchProjets(filters: ProjetFilter) {
    return axios.post<{
      projets: IProjetPartial[];
      pagination: IPagination;
      info: {
        nbTache: number;
        nbTacheEnCours: number;
        nbTacheEnRetards: number;
        nbProjets: number;
        nbProjetsProspection: number;
      };
    }>(`${BASE_ENDPOINT}/PROJETS`, filters);
  },
  getIntervenants(uidProjet) {
    return axios.get<{
      intervenants: IProjetIntervenant[];
      info: {
        nbTotal: number;
        nbEntreprise: number;
      };
    }>(`${BASE_ENDPOINT}/PROJET/${uidProjet}/INTERVENANTS`);
  },

  updateContact(uidProjet, indexInter, indexContact, payload) {
    const contact = {
      ...payload,
      mail: payload.email,
      tel: payload.telephone,
      prefixe_tel: payload.indicatif_telephone,
      port: payload.portable,
      prefixe_port: payload.indicatif_portable,
    };
    return axios.post(
      `${BASE_ENDPOINT}/PROJET/${uidProjet}/INTERVENANT/${indexInter}/CONTACT/${indexContact}`, //NEW
      contact
    );
  },
  createContact(uidProjet, indexInter, payload) {
    const contact = {
      ...payload,
      mail: payload.email,
      tel: payload.telephone,
      prefixe_tel: payload.indicatif_telephone,
      port: payload.portable,
      prefixe_port: payload.indicatif_portable,
    };
    return axios.put(
      `${BASE_ENDPOINT}/PROJET/${uidProjet}/INTERVENANT/${indexInter}/CONTACT`, //NEW
      contact
    );
  },
  deleteContact(uidProjet, indexInter, indexContact) {
    return axios.delete(
      `${BASE_ENDPOINT}/PROJET/${uidProjet}/INTERVENANT/${indexInter}/CONTACT/${indexContact}` //NEW
    );
  },
  getImageContact(uidProjet, indexImage) {
    return axios.get(`USER/PROJET/${uidProjet}/ANNUAIRE/IMAGE/${indexImage}`, {
      responseType: "blob",
    });
  },

  async getIntervenantContacts(uidProjet, indexInter) {
    const _contacts = await axios.get(
      `${BASE_ENDPOINT}/PROJET/${uidProjet}/INTERVENANT/${indexInter}/CONTACTS`
    );
    if (_contacts?.data?.contacts) {
      try {
        for (let i = 0; i < _contacts.data.contacts.length; i++) {
          _contacts.data.contacts[i] = {
            ..._contacts.data.contacts[i],
            email: _contacts.data.contacts[i].mail,
            telephone: _contacts.data.contacts[i].tel,
            indicatif_telephone: _contacts.data.contacts[i].prefixe_tel,
            portable: _contacts.data.contacts[i].port,
            indicatif_portable: _contacts.data.contacts[i].prefixe_port,
          };
        }
      } catch (error) {
        //
      }
    }
    return _contacts;
  },

  getProjetDocuments(uidProjet) {
    return axios.get<{
      dossiers: IProjetDossier[];
      fichiers: IProjetFichier[];
      info: object;
    }>(`${BASE_ENDPOINT}/PROJET/${uidProjet}/DOCS`);
  },
  getProjetPlans(uidProjet) {
    return axios.get<{
      dossiers: IPlan[];
      plans: IPlan[];
      versions: IPlan[]; 
    }>(`${BASE_ENDPOINT}/PROJET/${uidProjet}/PLANS`);
  },
  async addProjetDocument(uidProjet, file: IProjetDocument | IDossier) {
    return axios.put(`${BASE_ENDPOINT}/PROJET/${uidProjet}/DOC`, file);
  },
  
  async moveProjetDocument(uidProjet, index_doc,index_pere,est_dossier) {
    return axios.post(`${BASE_ENDPOINT}/PROJET/${uidProjet}/DOC/${index_doc}/MOVE`, {index_pere,est_dossier});
  },

  async renameProjetDocument(uidProjet, index_doc,nom,est_dossier) {
    return axios.post(`${BASE_ENDPOINT}/PROJET/${uidProjet}/DOC/${index_doc}/RENAME`, {nom,est_dossier});
  },
  async delProjetDocumentFile(uidProjet, index_doc) {
    return axios.delete(`${BASE_ENDPOINT}/PROJET/${uidProjet}/DOC/${index_doc}/DELETE/FILE`);
  },
  async delProjetDocumentFolder(uidProjet, index_doc) {
    return axios.delete(`${BASE_ENDPOINT}/PROJET/${uidProjet}/DOC/${index_doc}/DELETE/FOLDER`);
  },

  getProjetNotes(uidProjet) {
    return axios.get<{
      notes: IProjetNote[];
    }>(`${BASE_ENDPOINT}/PROJET/${uidProjet}/NOTES`);
  },
  /**
   * Recupere un projet
   */
  getProjetLogo(uidProjet: string) {
    return axios.get(`${BASE_ENDPOINT}/PROJET/${uidProjet}/LOGO`, {
      responseType: "blob",
    });
  },
  getIntervenantProjetLogo({ uid_projet, index_intervenant }) {
    return axios.get(
      `${BASE_ENDPOINT}/PROJET/${uid_projet}/INTERVENANT/${index_intervenant}/LOGO`,
      {
        responseType: "blob",
      }
    );
  },
};

export interface IProjetDocument extends IDossier, IDocument {
  extension: string;
  b64: string;
}

export interface IDossier {
  nom: string;
  pere: number;
  estFichier: boolean;
}
