export  function getUrlOfBlob(blob: any): string {
  try {
    if (blob) {
        const url =  window.URL.createObjectURL(blob);
        return url;
      }
  } catch (error) {
     //
  }
  return '';
}

export const blobToBase64 = (blob) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    if (blob && reader) {
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    } else {
      reject(false);
    }
  });