import {
  IConsultation,
  IConsultationBordereauDetail,
  IConsultationBordereauPrixConsult,
  IConsultationLot,
  IConsultationBordereau,
  IConsultationPieceAFournir,
  IConsultationQuestionReponse,
} from "@/@models/consultations";
import { IFichier } from "@/@models/fichier";
import {
  IIntervenant,
  IIntervenantContact,
  IIntervenantDocument,
  IIntervenantHistoOffre,
} from "@/@models/intervenants";
import { IUserIntervenant } from "@/@models/user";
import axios from "@/plugins/axios";
import { UserAccess } from "@/stores";
import { AxiosProgressEvent } from "axios";

const BASE_ENDPOINT = "ENTREPRISE/CONSULTATIONS";

export const consultationsService = {
  /**
   * Recupere l'ensemble des consultations de l'utilisateur
   */
  getConsultations() {
    return axios.get<{
      consultations: IConsultation[];
    }>(BASE_ENDPOINT);
  },

  getConsultationLotDecisionsEnums() {
    return axios.get(`${BASE_ENDPOINT}/ENUMS`);
  },

  /**
   * Recupere les evenements
   */
  getEvenements() {
    return axios.get<{
      evenements: any;
    }>(`ENTREPRISE/EVENEMENTS`);
  },

  /**
   * Recupere une consultations
   */
  getConsultation(uid: string) {
    return axios.get<{
      consultation: IConsultation;
    }>(`${BASE_ENDPOINT}/${uid}`);
  },



  sendEventOpenViewerIfc(uid: string) {
    return axios.get(`${BASE_ENDPOINT}/${uid}/VIEWER_IFC/EVENT`);
  },


  /**
   * Recupere tous les lots d'une consultations
   */
  getConsultationLots(uid: string) {
    return axios.get<{
      lots: IConsultationLot[];
    }>(`${BASE_ENDPOINT}/${uid}/LOTS`);
  },
  /**
   * Recupere un  lot d'une consultations
   */
  getConsultationLot(uid: string, uid_lot: string) {
    return axios.get<{
      lot: IConsultationLot;
    }>(`${BASE_ENDPOINT}/${uid}/LOTS/${uid_lot}`);
  },
  /**
   * Modifie la décision de réponse d'un lot
   */
  putLotDecision(
    uid_consult: string,
    uid_lot: string,
    decision: number,
    raisonDecision = "",
    raisonDecisionAutre = ""
  ) {
    return axios.put(`${BASE_ENDPOINT}/${uid_consult}/LOTS/${uid_lot}`, {
      decision,
      raisonDecision: raisonDecision,
      raisonDecisionAutre,
    });
  },

  submitOffre(uid_consult: string, uid_lot: string, uid_offre: string) {
    return axios.get<{
      result: boolean;
    }>(
      `${BASE_ENDPOINT}/${uid_consult}/LOTS/${uid_lot}/OFFRES/${uid_offre}/TRANSMISSION`
    );
  },

  updateOffreInfoEntreprise(
    uid_consult: string,
    uid_lot: string,
    uid_offre: string,
    data: any
  ) {
    return axios.put<{
      result: boolean;
    }>(`${BASE_ENDPOINT}/${uid_consult}/LOTS/${uid_lot}/OFFRES/${uid_offre}`, {
      infoEntreprise: data,
    });
  },

  /**
   * Recupere les bordereaux d'une offre
   */
  getConsultationBordereaux(uid_lot: string, uid_offre: string) {
    return axios.get<{
      bordereaux: IConsultationBordereau[];
    }>(`ENTREPRISE/LOTS/${uid_lot}/OFFRES/${uid_offre}/BORDEREAUX`);
  },
  /**
   * Recupere un bordereau complet
   */
  getConsultationBordereau(
    uid_lot: string,
    uid_offre: string,
    uid_bordereau: string
  ) {
    return axios.get<{
      bordereau: IConsultationBordereauDetail;
    }>(
      `ENTREPRISE/LOTS/${uid_lot}/OFFRES/${uid_offre}/BORDEREAUX/${uid_bordereau}`
    );
  },
  /**
   * Recupere l'ensemble de la documententation DCE de la consultation
   */
  getDocumentsDCE(uid: string) {
    return axios.get<{
      documents: IFichier[];
    }>(`${BASE_ENDPOINT}/${uid}/DCE`);
  },
  /**
   * Recupere l'ensemble des questions/reponses de la consultation
   */
  getQestionsReponses(uid: string) {
    return axios.get<{
      questions: IConsultationQuestionReponse[];
    }>(`${BASE_ENDPOINT}/${uid}/QUESTIONS`);
  },
  postQuestionReponse(
    uidConsultation: string,
    data: IConsultationQuestionReponse
  ) {
    return axios.post(`${BASE_ENDPOINT}/${uidConsultation}/QUESTIONS`, data);
  },

  getIntervenantUser() {
    return axios.get<{ user: IIntervenantContact }>(
      `ENTREPRISE/INTERVENANT/USER`
    );
  },

  getIntervenant() {
    return axios.get<{ intervenant: IIntervenant }>(
      `ENTREPRISE/INTERVENANT/INFORMATION`
    );
  },

  getContacts() {
    return axios.get<{ contacts: IIntervenantContact[] }>(
      `ENTREPRISE/INTERVENANT/CONTACTS`
    );
  },

  getDocuments() {
    return axios.get<{ documents: IIntervenantDocument[] }>(
      `ENTREPRISE/INTERVENANT/DOCUMENTS`
    );
  },

  /**
   * Indique que la consultation est visité
   */
  setConsultationVisite(uid: string, visite = true) {
    return axios.post(`${BASE_ENDPOINT}/${uid}/VISITE`, { visite });
  },

  putBordereauPrix(
    uid_lot: string,
    uid_offre: string,
    uid_bordereau: string,
    data: Partial<IConsultationBordereauPrixConsult>[]
  ) {
    return axios.put<{
      success: boolean;
    }>(
      `ENTREPRISE/LOTS/${uid_lot}/OFFRES/${uid_offre}/BORDEREAUX/${uid_bordereau}/PRIX`,
      data
    );
  },

  postBordereauPrix(
    uid_lot: string,
    uid_offre: string,
    uid_bordereau: string,
    data: Partial<IConsultationBordereauPrixConsult>[]
  ) {
    return axios.post<{
      success: boolean;
    }>(
      `ENTREPRISE/LOTS/${uid_lot}/OFFRES/${uid_offre}/BORDEREAUX/${uid_bordereau}/PRIX`,
      data
    );
  },

  deleteBordereauPrix(
    uid_lot: string,
    uid_offre: string,
    uid_bordereau: string,
    uid_prix: string
  ) {
    return axios.delete<{
      success: boolean;
    }>(
      `ENTREPRISE/LOTS/${uid_lot}/OFFRES/${uid_offre}/BORDEREAUX/${uid_bordereau}/PRIX/${uid_prix}`
    );
  },

  putIntervenant(payload: IIntervenant) {
    return axios.put(`ENTREPRISE/INTERVENANT/INFORMATION`, payload);
  },

  postContact(indexContact: number, payload: IUserIntervenant) {
    return axios.post(
      `ENTREPRISE/INTERVENANT/CONTACT/${indexContact}`,
      payload
    );
  },

  getPieceAFournir(uidConsultation) {
    return axios.get<{ piecesAFournir: IConsultationPieceAFournir[] }>(
      `${BASE_ENDPOINT}/${uidConsultation}/CANDIDATURE`
    );
  },

  getIntervenantHistoriqueOffres() {
    return axios.get<{ offres: IIntervenantHistoOffre[] }>(
      `ENTREPRISE/INTERVENANT/HISTORIQUE/OFFRES`
    );
  },

  postPieceAFournir(
    uidConsultation,
    uidPiece,
    piece?: File,
    uidDocEntreprise?,
    uploadPredicate?: (progressEvent: AxiosProgressEvent) => void
  ) {
    const formData = new FormData();
    if (piece) {
      formData.append("piece", piece);
    } else if (uidDocEntreprise) {
      formData.append("doc", uidDocEntreprise);
    }

    return axios.post(
      `${BASE_ENDPOINT}/${uidConsultation}/CANDIDATURE/${uidPiece}`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: uploadPredicate,
      }
    );
  },

  postDocumentInter(
    info?,
    fichier?: File,
    uploadPredicate?: (progressEvent: AxiosProgressEvent) => void
  ) {
    const formData = new FormData();
    if (fichier) {
      formData.append("fichier", fichier);
    }
    if (info) {
      formData.append("nom", info.nom);
      formData.append("description", info.description);
      formData.append("commentaire", info.commentaire);
    }

    return axios.post("ENTREPRISE/INTERVENANT/DOCUMENTS", formData, {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: uploadPredicate,
    });
  },

  putDocumentInter(
    uidDoc,
    data?,
    fichier?: File,
    uploadPredicate?: (progressEvent: AxiosProgressEvent) => void
  ) {
    const formData = new FormData();
    if (fichier) {
      formData.append("fichier", fichier);
    }
    if (data) {
      formData.append("nom", data.nom);
      formData.append("description", data.description);
    }

    return axios.put(`ENTREPRISE/INTERVENANT/DOCUMENTS/${uidDoc}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: uploadPredicate,
    });
  },

  deleteFichierPieceAFournir(uidConsultation, uidPiece) {
    return axios.delete(
      `${BASE_ENDPOINT}/${uidConsultation}/CANDIDATURE/${uidPiece}`
    );
  },

  deleteDocumentInter(uidDoc) {
    return axios.delete(`ENTREPRISE/INTERVENANT/DOCUMENTS/${uidDoc}`);
  },
};
