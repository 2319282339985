<template>
  <div
    class="absolute left-0 top-0 z-99999 h-full w-full select-none"
    v-if="customAlertPopup"
    @contextmenu.prevent="() => {}"
    @click="hide"
  >
    <CustomAlert
      @hide="setCustomAlertPopup(null)"
      v-if="customAlertPopup"
      :options="customAlertPopup"
    />
  </div>
</template>

<script lang="ts" setup>
import useLayout from "@/pages/bureau/composables/useLayout";
import CustomAlert from "@/components/CustomAlert.vue";

const { setCustomAlertPopup, customAlertPopup } = useLayout();

function hide() {
  setCustomAlertPopup(undefined);
}
</script>
