import { IConsultation } from "@/@models/consultations";
import { consultationsService } from "@/api/consultations";
import { checkData, isPreviewInUrl, isValidStatus } from "@/utils";
import { UUID_vide } from "@/utils/tokens";
import { defineStore } from "pinia";
import { useToast } from "vue-toastification";

const toastification = isPreviewInUrl()
  ? {
      error: () => {
        /**/
      },
      success: () => {
        /**/
      },
    }
  : useToast();

/**
 * Type authentification: Intervenant
 */
export const useConsultationsStore = defineStore("consultations", {
  state: () => {
    return {
      consultations: [] as IConsultation[],
      error: null,
    };
  },
  getters: {
    getConsultationByID(state) {
      return (uid) => state.consultations.find((c) => c.uid == uid);
    },
  },
  actions: {
    /**
     * Recuperes les consultations
     * @returns
     */
    async fetchConsultations() {
      if (isPreviewInUrl()) {
        this.consultations =[
            {
                uid:UUID_vide,
                nom:"Consultation générale",
                dateDebut:"2021-09-01",
                dateFin:"2021-09-30",
                dateFinNego:"2021-09-30",
                statut:2,
                procedure:"MAPA",
                tva:[5.5,10,20],
                estVisite:true,
                viewerIfc:false,
                confidentialite:0,
                projet:{
                    uid:UUID_vide,
                    index:1,
                    code:"AA01",
                    nom:"SOLARIS",
                    adresse:"",
                    cp:"75000",
                    ville:"Paris",
                    descriptif:"",
                    budget:0,
                    nature:"maisons individuelles",
                    dateDebutTrav:"2021-09-01",
                    dateFinTrav:"2021-09-30",
                    appelation:"Appelation",
                    dateLivraison:"2021-09-30",
                },
                moa:{
                    index:1,
                    sigle:"",
                    nom:"SMITH John",
                },
            },
            {
                uid:UUID_vide,
                nom:"Consultation avec options",
                dateDebut:"2022-02-01",
                dateFin:"2022-07-30",
                dateFinNego:"2022-07-30",
                statut:1,
                procedure:"MAPA",
                tva:[5.5,10,20],
                estVisite:true,
                viewerIfc:false,
                confidentialite:1,
                projet:{
                    uid:UUID_vide,
                    index:1,
                    code:"AA02",
                    nom:"Les prairies - Rénovation",
                    adresse:"",
                    cp:"51100",
                    ville:"Reims",
                    descriptif:"",
                    budget:0,
                    nature:"locaux commerciaux",
                    dateDebutTrav:"2022-02-01",
                    dateFinTrav:"2022-07-30",
                    appelation:"Appelation",
                    dateLivraison:"2022-07-30",
                },
                moa:{
                    index:1,
                    sigle:"",
                    nom:"SMITH John",
                },
            },
        ]
        return true;
      }

      const { status, data } = await consultationsService.getConsultations();
      if (!isValidStatus(status) || !checkData(data)) {
        this.consultations = [];
        toastification.error("La récupération des consultations a échoué.");
        return false;
      }
      this.consultations = data.consultations;
      return true;
    },
  },
});
