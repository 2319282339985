import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["onClick"]

import { defineComponent, onMounted, onUnmounted, ref } from "vue";

import useLayout from "@/pages/bureau/composables/useLayout";
import { watch } from "vue";
import { IOptionListItem } from "./SidebarRightOptions";
import FadeTransition from "@/components/transitions/FadeTransition.vue";
import { awaitData, isMobile } from "@/utils";

 


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomContextMenu',
  setup(__props) {

const { setCustomContextMenu, customContextMenu } = useLayout();

function hide() {
  setCustomContextMenu(undefined);
}

const windowWidth = ref(0);
const windowHeight = ref(0);

function resize() {
  windowWidth.value = window.innerWidth;
  windowHeight.value = window.innerHeight;
}

onMounted(() => {
  windowWidth.value = window.innerWidth;
  windowHeight.value = window.innerHeight;
  window.addEventListener("resize", resize);
});

onUnmounted(() => {
  window.removeEventListener("resize", resize);
});

const customContextMenuLoading = ref(false);
const contextMenuStyle = ref({
  top: undefined,
  left: undefined,
  right: undefined,
  bottom: undefined,
});
watch(
  customContextMenu,
  async (value) => {
    if (value) {
      customContextMenuLoading.value = true;
      let contextMenuWidth = 0;
      let contextMenuHeight = 0;
      await awaitData(() => {
        contextMenuWidth =
          document.getElementById("customContextMenu")?.clientWidth;
        contextMenuHeight =
          document.getElementById("customContextMenu")?.clientHeight;
        return contextMenuWidth && contextMenuHeight;
      });

      let left = value.x;
      let top = value.y;
      let right = undefined;
      let bottom = undefined;

      //si le menu deborde a droite on positionne avec right:0
      if (left + contextMenuWidth > windowWidth.value) {
        left = undefined;
        right = 0;
      }
      //si le menu deborde en bas on positionne avec bottom:0
      if (top + contextMenuHeight > windowHeight.value) {
        top = undefined;
        bottom = 0;
      }
      contextMenuStyle.value.left = !isNaN(left) ? left + "px" : undefined;
      contextMenuStyle.value.top = !isNaN(top) ? top + "px" : undefined;
      contextMenuStyle.value.right = !isNaN(right) ? right + "px" : undefined;
      contextMenuStyle.value.bottom = !isNaN(bottom)
        ? bottom + "px"
        : undefined;

      customContextMenuLoading.value = false;
    }
  },
  { deep: true }
);

return (_ctx: any,_cache: any) => {
  const _component_b_icon = _resolveComponent("b-icon")!

  return (_unref(customContextMenu) && _unref(customContextMenu).items?.length > 0)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "absolute left-0 top-0 z-99999 h-full w-full select-none",
        onContextmenu: _withModifiers(() => {}, ["prevent"]),
        onClick: hide
      }, [
        _createElementVNode("div", {
          id: "customContextMenu",
          class: _normalizeClass(["absolute flex w-fit flex-col rounded-lg border-2 bg-element p-1 shadow-xl", {
        'opacity-0': customContextMenuLoading.value,
      }]),
          style: _normalizeStyle(contextMenuStyle.value)
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(customContextMenu)?.items.sort(
          (a, b) => a.index - b.index
        ), (option, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              onClick: option.action,
              class: _normalizeClass(["cursor-pointer inline-flex items-center whitespace-nowrap rounded-lg px-2 hover:bg-primary-light", {
          ' py-2' :  _unref(isMobile)()
        }])
            }, [
              (option?.icon)
                ? (_openBlock(), _createBlock(_component_b_icon, {
                    key: 0,
                    name: option.icon,
                    size: "auto",
                    class: "pr-1 h-4"
                  }, null, 8, ["name"]))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(option.label), 1)
            ], 10, _hoisted_1))
          }), 128))
        ], 6)
      ], 32))
    : _createCommentVNode("", true)
}
}

})