import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, isRef as _isRef, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  class: "h-full text-xl font-semibold w-full flex items-center justify-center"
}
const _hoisted_4 = {
  "aria-labelledby": "dropdownUser",
  class: "w-60 space-y-2 rounded-xl border bg-menu p-1 shadow-2xl dark:border-dark"
}
const _hoisted_5 = { class: "px-2" }
const _hoisted_6 = {
  key: 0,
  class: "p-2"
}
const _hoisted_7 = { class: "font-bold inline-flex items-center gap-x-1" }
const _hoisted_8 = { class: "font-semibold inline-flex items-center gap-x-1" }
const _hoisted_9 = { class: "text-sm inline-flex items-center gap-x-1" }
const _hoisted_10 = { class: "px-2" }

import { createPopper } from "@/plugins/popper";
import { useAuthStore } from "@/stores";
import { useAOStore } from "@/pages/ao/store";
import { storeToRefs } from "pinia";
import { defineComponent, nextTick, ref, watch,computed } from "vue";
import { useRouter } from "vue-router";
import { BInputSwitch, BBtn, BDropdown ,BIcon} from "@/components/global";
import { User,Mail,Contact } from "@/components/global/icons";
import { onMounted } from "vue";
import { useEntrepriseStore } from "@/pages/ao/store";
import { isPreviewInUrl } from "@/utils";


export default /*@__PURE__*/_defineComponent({
  __name: 'AccountPopover',
  setup(__props) {

defineComponent({
  name: "account-popover",
});

let popperInstance = null;

const show = ref(false);

const userTriggerEl = ref();
const userTargetEl = ref();

const aoStore = useAOStore();
const authStore = useAuthStore();
const entrepriseStore = useEntrepriseStore();

const { darkMode } = storeToRefs(aoStore);
const { user } = storeToRefs(authStore);
const { intervenant } = storeToRefs(entrepriseStore);

const router = useRouter();

const logout = () => {
  authStore.logout();
  router.push({ name: "login" });
};
onMounted(async () => {
  await entrepriseStore.fetchIntervenant();
});

watch(
  () => show.value,
  (newValue) => {
    if (newValue) {
      nextTick(() => {
        popperInstance = createPopper(userTriggerEl.value, userTargetEl.value, {
          placement: "bottom",
        });
      });
    } else if (popperInstance) {
      setTimeout(() => popperInstance.destroy(), 250);
    }
  }
);

const intervenantInitials = computed(() => {
  const nom = intervenant.value?.nom || "";
  let toreturn = ""; 
  
  //split " "
  toreturn =  nom.split(" ").map((word) => word[0]).join("");
  
  //si toreturn.length < 2 alors on vas remplacer le camelCase par un espace
  if(toreturn.length < 2){
    toreturn =  nom.replace(/([a-z])([A-Z])/g, '$1 $2').split(" ").map((word) => word[0]).join("");
  }


  return toreturn;
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BDropdown), {
    arrow: "",
    stopPropagation: ""
  }, {
    trigger: _withCtx(({ toggle }) => [
      _createElementVNode("button", {
        onClick: ($event: any) => ((!_unref(isPreviewInUrl)()) && toggle()),
        class: "inline-flex items-center rounded-full border-primary border-2 text-center text-sm text-white focus:outline-none focus:ring-4 active:opacity-75"
      }, [
        (_unref(isPreviewInUrl)())
          ? (_openBlock(), _createBlock(_unref(BIcon), {
              key: 0,
              name: "user",
              size: "auto",
              class: "h-10 w-10 overflow-hidden rounded-full text-gray-600"
            }))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["h-10 w-10 overflow-hidden rounded-full", _unref(intervenant)?.image? '':' p-0.5'])
            }, [
              (_unref(intervenant)?.image)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src:  `data:image/png;base64,${_unref(intervenant)?.image}`,
                    alt: "avatar",
                    class: "h-full w-full object-contain"
                  }, null, 8, _hoisted_2))
                : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(intervenantInitials.value), 1))
            ], 2)),
        _cache[2] || (_cache[2] = _createElementVNode("span", { class: "sr-only" }, "Open user menu", -1))
      ], 8, _hoisted_1)
    ]),
    default: _withCtx(() => [
      _createElementVNode("ul", _hoisted_4, [
        _createElementVNode("li", _hoisted_5, [
          (_unref(user))
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _cache[3] || (_cache[3] = _createTextVNode(" Bienvenue")),
                _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("span", _hoisted_7, [
                  _createVNode(_unref(User)),
                  _createTextVNode(" " + _toDisplayString(`${_unref(intervenant).nom}`), 1)
                ]),
                _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("span", _hoisted_8, [
                  _createVNode(_unref(Contact)),
                  _createTextVNode(" " + _toDisplayString(`${_unref(user).prenom} ${_unref(user).nom}`), 1)
                ]),
                _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("span", _hoisted_9, [
                  _createVNode(_unref(Mail)),
                  _createTextVNode(" " + _toDisplayString(_unref(user).email), 1)
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("li", _hoisted_10, [
          _createVNode(_unref(BInputSwitch), {
            modelValue: _unref(darkMode),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(darkMode) ? (darkMode).value = $event : null)),
            label: "Mode sombre"
          }, null, 8, ["modelValue"])
        ]),
        _cache[8] || (_cache[8] = _createElementVNode("li", { class: "mt-3 flex flex-col px-2" }, [
          _createElementVNode("span", null, "Besoin d'aide ?"),
          _createElementVNode("a", {
            class: "text-primary underline hover:opacity-75",
            href: "https://docs.google.com/document/d/1NHstnNHCB1rowIM4hXCu70i6z8Agdxo50JG58TwZkS0/edit",
            target: "_blank"
          }, "Documentation")
        ], -1)),
        _createElementVNode("li", null, [
          _createVNode(_unref(BBtn), {
            variant: "danger",
            size: "full",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (logout()))
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createTextVNode("Déconnexion")
            ])),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}
}

})