export function decouperFichierB64(fichierB64: string) {
  const sliced20MoArray = [];
  for (let i = 0; i < fichierB64.length; i += 20 * 1000 * 1000) {
    sliced20MoArray.push(fichierB64.slice(i, i + 20 * 1000 * 1000));
  }
  return sliced20MoArray;
}
export async function FileToBase64(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target.result as string);
    };
    reader.onerror = (e) => {
      reject(e);
    };
    reader.readAsDataURL(file);
  });
}

export const b64SizeMax = 20 * 1000 * 1000; //chunk de 20Mo

export function getNumberOfChunks(b64: string) {
  return Math.ceil(b64.length / b64SizeMax);
}

export function getChunk(b64: string, index: number) {
  return b64.slice(index * b64SizeMax, (index + 1) * b64SizeMax);
}

export function getExtension(filename: string) {
  return filename.split(".").pop();
}

export function formatFileSize(size: number, precision = 2) {
  // en fonction de la taille, on retourne le nombre de o, ko, Mo, Go, To
  
  function sliceUselessZero(str: string) {
    // on supprime les 0 inutiles après la virgule
    let i = str.length - 1;
    while (str[i] === "0") {
      i--;
    }
    if (str[i] === ".") {
      i--;
    }
    return str.slice(0, i + 1);
  }
  let res = 0;
  let unit = "";
  if (size < 1000) {
    res = size;
    unit = "o";
  }
  if (size >= 1000) {
    res = size / 1000;
    unit = "ko";
  }
  if (size >= 1000000) {
    res = size / 1000000;
    unit = "Mo";
  }
  if (size >= 1000000000) {
    res = size / 1000000000;
    unit = "Go";
  }
  if (size >= 1000000000000) {
    res = size / 1000000000000;
    unit = "To";
  }

  if (precision > 0) {
    return sliceUselessZero(res.toFixed(precision)) + " " + unit;
  } else {
    return Math.floor(res) + " " + unit;
  }

}

import { iconTypeValues } from "@/components/global/index";
import { isSafariMobile } from "./device";
export function getFileExtensionIconName(fileName) {
  if (!fileName) return "file_any";
  const extension = getExtension(fileName);
  if ((iconTypeValues as any).includes("file_" + extension)) {
    return "file_" + extension;
  } else if (extension === "jpeg") {
    return "file_jpg";
  } else {
    return "file_any";
  }
}


export async function downloadFileFromBase64(base64: string, filename: string) {
  if (isSafariMobile()) {
    const b64 =
      "data:application/octet-stream;base64," + base64.split(",")[1];

    const a = document.createElement("a");
    a.href = b64;
    a.download =filename;
    a.click();
    a.remove();

    return;
  }

  const a = document.createElement("a");
  a.href = base64;
  a.download = filename;
  a.click();
  a.remove();

}