import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref } from "vue"

const _hoisted_1 = {
  class: "flex h-full w-full items-center justify-center",
  role: "button"
}
const _hoisted_2 = {
  key: 1,
  class: "absolute left-2 top-0 z-10 w-12 rounded-b-md border-4"
}
const _hoisted_3 = { key: 1 }

import { blobToBase64, isMobile, isSafari, isSafariMobile } from "@/utils";
import { downloadFileFromBase64 } from "@/utils/file";
import { ref } from "vue";
import { onMounted } from "vue";
import VuePdfEmbed from "vue-pdf-embed";


export default /*@__PURE__*/_defineComponent({
  __name: 'PdfViewer',
  props: {
  blob: {
    type: Blob,
  },
  filename: {
    type: String,
    default: "document_.pdf",
  },
},
  setup(__props) {

 const props = __props;
const base64 = ref(null);
 
onMounted(async () => { 
   base64.value  = await blobToBase64(props.blob)
});
const download = async() => {
  if (base64.value) {
    downloadFileFromBase64(base64.value, props.filename);
  }
};

const toggleMenu = ref(false);

return (_ctx: any,_cache: any) => {
  const _component_b_icon = _resolveComponent("b-icon")!

  return (__props.blob && base64.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "h-full w-full overflow-auto",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (toggleMenu.value = !toggleMenu.value))
      }, [
        (toggleMenu.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "absolute left-2 top-2 z-10 h-12 w-12 rounded-xl border-2 bg-element p-2 shadow-lg transition-all duration-200 md:-translate-y-full md:group-hover:translate-y-0",
              onClick: _cache[0] || (_cache[0] = 
        (e) => {
          e.stopPropagation();
          download();
        }
      )
            }, [
              _createElementVNode("button", _hoisted_1, [
                _createVNode(_component_b_icon, {
                  size: "auto",
                  class: "h-full w-full",
                  name: "download_bis"
                })
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_2)),
        _createVNode(_unref(VuePdfEmbed), { source: base64.value }, null, 8, ["source"])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3))
}
}

})