import { IEntity } from ".";
import { IFichier } from "./fichier";
import dayjs from "dayjs";

export type ConsultationLotOffers = IConsultationLot & {
  hasLastOffer?: boolean;
  totalHt?: number;
  optionHt?: number;
  complementHt?: number;
  etat?: number;
  dateTransmission?: dayjs.Dayjs;
};

export interface IConsultation extends Omit<IEntity, "index"> {
  uid: string;
  nom: string;
  dateDebut: string;
  dateFin: string;
  dateFinNego: string;
  statut: StatutType;
  procedure: string;

  tva: number[];
  estVisite: boolean;
  viewerIfc: boolean;

  ao?: IConsultationAO;
  projet?: IConsultationProjet;
  moa?: IConsultationMoa;
  architecte?: IConsultationArchi;
  chargeProjet?: IConsultationChargeProjet;
  societe?: IConsultationSociete;
  fichierCao?: IConsultationFichierCao;

  params?: IConsultationParameters;

  confidentialite: number;
}

export interface IConsultationProjet {
  uid: string;
  index: number;
  code: string;
  nom: string;
  adresse: string;
  cp: string;
  ville: string;
  descriptif: string;
  budget: number;
  nature: string;
  dateDebutTrav: string;
  dateFinTrav: string;
  appelation: string;
  dateLivraison: string;

  web?: string;
  surf_construire?: string;
  surf_transformer?: string;
  surf_habitable?: string;
}

export interface IConsultationMoa {
  index: number;
  sigle: string;
  nom: string;
  email: string;
  telephone: string;
  adresse: string;
  codePostal: string;
  ville: string;
  pays: string;
  url?: string;

  image?: string;
  contact?: {
    index: number;
    fonction: string;
    nom: string;
    prenom: string;
    genre: string;
    email: string;
    portable?: {
      prefix: string;
      numero: string;
      suffix: string;
    };
    fixe?: {
      prefix: string;
      numero: string;
      suffix: string;
    };
    fax?: {
      prefix: string;
      numero: string;
      suffix: string;
    };
  };
}

export interface IConsultationArchi {
  index: number;
  sigle: string;
  nom: string;
}

export interface IConsultationChargeProjet {
  index: number;
  sigle: string;
  nom: string;
  prenom: string;
}

export interface IConsultationSociete {
  adresse: string;
  cp: string;
  ville: string;
}

export interface IConsultationAO {
  datePrevDebut?: string;
  datePrevFin?: string;
  datePrevLivraison?: string;

  rensAdministratif?: string;
  rensComplement?: string;
  rensTechnique?: string;
}

export interface IConsultationFichierCao {
  uid: string;
  uidEtude: string;
  nom: string;
  estConsultable: boolean;
}

export interface IConsultationQuestionReponse {
  uid: string;
  parentUid: string;
  messageHtml: string;
  message: string;
  libelle: string;
  estAnnonce: boolean;
  type: number;
  estImportant: boolean;
  ordre: number;
  tags: string;
  date: string;
  estPublic: boolean;

  emetteur?: {
    index?: number;
    nom?: string;
    sigle?: string;
    estIntervenantProjet?: boolean;
  };

  reponses: IConsultationQuestionReponse[];

  showTooltip?: boolean;
}

export interface IConsultationLot {
  uid: string;
  nom: string;
  code: string;
  statut: number;
  estTransmis: boolean;
  estRetenu: boolean;
  offres?: IConsultationLotOffre[];
  decision: number;
  dateDecision: string;
  raisonDecision: string;
  raisonDecisionAutre: string;
}

export interface IConsultationLotOffre {
  uid: string;
  nom: string;
  ordre: number;
  etat: number;
  decision: number;
  dateTransmission: string;
  dateModif: string;
  estManuelle?: boolean;
  estOuverture: boolean;
  estRetenu: boolean;
  complementHt: number;
  totalHt: number;
  optionHt: number;
  infoEntreprise?: any;

  /**
   * Champ calculé
   */
  complementTtc?: number;
  /**
   * Champ calculé
   */
  totalTtc?: number;
  /**
   * Champ calculé
   */
  optionTtc?: number;

  montantHt1: number;
  montantHt2: number;
  montantHt3: number;
  montantHt4: number;
  montantHt5: number;
  bordereaux: IConsultationBordereau[];
}

export interface IConsultationBordereau {
  uid: string;
  nom: string;
  code: string;
  numNegoce: number;
  ordre: number;
  complementHt: number;
  complementTtc: number;
  valeurHt: number;
  valeurTtc: number;
  estOption: boolean;
}

export interface IConsultationBordereauDetail {
  uid: string;
  nom: string;
  code: string;
  numNegoce: number;
  ordre: number;
  complementHt: number;
  complementTtc: number;
  valeurHt: number;
  valeurTtc: number;
  estOption: boolean;
  details: IConsultationBordereauPrixConsult[];
}

export interface IConsultationBordereauPrixConsult {
  uid: string;
  uidOuvrage?: string;
  code: string;
  nom: string;
  descriptif: string;
  estArticle: boolean;
  estGeneralite: boolean;
  estComplement: boolean;
  estVariante: boolean;
  quantite: number;
  prixUnit: number;
  tauxTva: number;
  ordre: number;
  estPrisEnCompte: boolean;
  annotation: string;
  ouvrage?: {
    uid: string;
    descriptif: string;
    typeTva: number;
    quantiteMoe: number;
    unite: string;
    uidTexteRiche: string;
  };
  niveau: number;
  isTotal: boolean;
  prixTotal: number;
  parentUid: string;
  unite: string;
  articleRef: string;

  estForfait: boolean;
  estElementForfait: boolean;

  ouvrages: IConsultationBordereauPrixConsult[];

  /**
   * Champ ajouté
   */
  isAddedRow?: boolean;
  /**
   * Champ ajouté
   */
  isExpanded?: boolean;
  /**
   * Champ ajouté
   */
  isDeleted?: boolean;
  /**
   * Champ ajouté
   */
  errorMessageQuantite?: string;
  /**
   * Champ ajouté
   */
  errorMessagePrixUnit?: string;
  /**
   * Champ ajouté
   */
  isEdited?: boolean;
  /**
   * Champ ajouté
   */
  isHidden?: boolean;
}

export type IConsultationParameters = {
  liste_image: IFichier[];

  gen_poserQuestion: boolean;
  gen_resultatLot: boolean;

  prj_architecte: boolean;
  prj_budget: boolean;
  prj_surface: boolean;
  prj_calendrier: boolean;
  prj_chargeAffaire: boolean;
  prj_siteweb: boolean;
  prj_programme: boolean;
  prj_map: boolean;
  prj_natureTvx: boolean;

  mo_logoMO: boolean;
  mo_adressePostale: boolean;
  mo_contact: boolean;
  mo_siteweb: boolean;

  cons_nivConfidentialite: boolean;
  cons_rensTech: boolean;
  cons_rensAdmin: boolean;
  cons_rensComp: boolean;
  cons_remiseOffre: boolean;
  cons_periode: boolean;

  sp_ligneComplement: boolean;
  sp_montantForfaitaire: boolean;
  sp_propreQuantite: boolean;
  sp_ajtVariant: boolean;
};


export interface IConsultationPieceAFournir {
  uid: string;
  nom: string;
  ordre: number;
  description: string;

  type: IConsultationPieceAFournirType;
  pieces: IFichier[];
}

export interface IConsultationPieceAFournirType {
  uid: string;
  nom: string;
}

export enum StatutType {
  aucun = 0,
  enCours = 1,
  nego = 2,
  terminer = 3,
  archiver = 4,
}
