import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "my-1 inline-flex rounded-lg p-2"
}
const _hoisted_2 = { class: "flex w-full flex-col" }

import {
  computed,
  nextTick,
  PropType,
  ref,
  watch,
  WritableComputedRef,
} from "vue";
import BCustomDatePicker from "./BCustomDatePicker.vue";
import BCustomRangeDatePicker from "./BCustomRangeDatePicker.vue";
import { defaultTypeDates, INavigatorTypeDate } from "./BCustomDatePicker";
import { BInputSelect } from "@/components/global";
import pointagesClass from "@/pages/bureau/store/class/pointages.class";
import dayjs from "dayjs";

/**
 * @modelValue: la date sélectionnée au format [date1, date2]
 * @typeDate: le type de date sélectionné
 * @typeDateArray: les types de date disponibles
 */

export default /*@__PURE__*/_defineComponent({
  __name: 'BCustomDateNavigator',
  props: {
  date: {
    type: Array as PropType<string[]>,
    required: true,
  },
  typeDate: {
    type: String as PropType<INavigatorTypeDate>,
    required: true,
  },
  typeDateArray: {
    type: Array as PropType<INavigatorTypeDate[]>,
    required: true,
  },
  canNavigate: {
    type: Boolean,
    default: false,
  },
  preDate: {
    type: String,
    default: "",
  },
  nextDate: {
    type: String,
    default: "",
  },
  all_dates: {
    type: Array as PropType<string[]>,
  },
},
  emits: ["updateDate", "updateTypeDate"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;
const modelDate: WritableComputedRef<string[]> = computed({
  get() {
    return props.date;
  },
  set(newValue): void {
    function emitUpdate() {
      saveDatesString =
        JSON.stringify(newValue) + JSON.stringify(modelTypeDate.value);
      emit("updateDate", newValue);
    }
    if (
      saveDatesString !=
      JSON.stringify(newValue) + JSON.stringify(modelTypeDate.value)
    ) {
      emitUpdate();
    }
  },
});
let saveDatesString = "";
const modelTypeDate: WritableComputedRef<string> = computed({
  get() {
    return props.typeDate;
  },
  async set(newValue): Promise<void> {
    emit("updateTypeDate", newValue);
    updateNavigationDate(modelDate.value[0], newValue);
  },
});
function navigationPending() {
  return new Date().getTime() - updateNavigationTS < 600;
}
let updateNavigationTS = 0;
function updateNavigationDate(date: string, forceModelType = ""): void {
  if (!date) {
    return;
  }

  if (navigationPending()) {
    return;
  }
  updateNavigationTS = new Date().getTime();

  let firstDay = "";
  let lastDay = "";
  const type = forceModelType ? forceModelType : modelTypeDate.value;
  switch (type) {
    case "day":
      modelDate.value = [date, date];
      break;
    case "week":
      firstDay = dayjs(date).startOf("week").format("YYYY-MM-DD");
      lastDay = dayjs(date).endOf("week").format("YYYY-MM-DD");
      modelDate.value = [firstDay, lastDay];
      break;
    case "month":
      firstDay = dayjs(date).startOf("month").format("YYYY-MM-DD");
      lastDay = dayjs(date).endOf("month").format("YYYY-MM-DD");
      modelDate.value = [firstDay, lastDay];
      break;
    case "year":
      firstDay = dayjs(date).startOf("year").format("YYYY-MM-DD");
      lastDay = dayjs(date).endOf("year").format("YYYY-MM-DD");
      modelDate.value = [firstDay, lastDay];
      break;
    case "all":
      modelDate.value = ["", ""];
      break;
    case "custom":
      {
        //ici on vas calculer la période actuelle
        const actual_start = dayjs(modelDate.value[0]);
        const actual_end = dayjs(modelDate.value[1]);
        const periode = actual_end.diff(actual_start, "days");
        //ensuite on vas verifier si la nouvelle date est plus grande que la date actuelle
        //on vas docn comparer actual_end avec date
        if (dayjs(date).diff(actual_end, "days") > 0) {
          //on vas calculer la différence entre  date et actual_end
          const diff = dayjs(date).diff(actual_end, "days");
          //on récupère le nombre de période entre les deux dates
          const nb_periode = Math.ceil(diff / periode);
          //on vas ajouter nb_periode à la date de debut et de fin
          modelDate.value = [
            actual_start.add(nb_periode * periode, "days").format("YYYY-MM-DD"),
            actual_end.add(nb_periode * periode, "days").format("YYYY-MM-DD"),
          ];
        } else {
          //on vas calculer la différence entre  date et actual_start
          const diff = Math.abs(dayjs(date).diff(actual_start, "days"));
          //on récupère le nombre de période entre les deux dates
          const nb_periode = Math.ceil(diff / periode);
          //on vas ajouter nb_periode à la date de debut et de fin
          modelDate.value = [
            actual_start
              .subtract(nb_periode * periode, "days")
              .format("YYYY-MM-DD"),
            actual_end
              .subtract(nb_periode * periode, "days")
              .format("YYYY-MM-DD"),
          ];
        }
      }
      break;
  }
}

return (_ctx: any,_cache: any) => {
  const _component_BIcon = _resolveComponent("BIcon")!

  return (__props.typeDate && __props.date.length == 2)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (__props.canNavigate)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (updateNavigationDate(__props.preDate))),
              class: _normalizeClass(["flex h-full w-10 items-center justify-center", {
        'cursor-pointer hover:text-primary ': __props.preDate && modelTypeDate.value != 'all',
      }])
            }, [
              _createVNode(_component_BIcon, {
                name: "chevron_left",
                size: "lg",
                class: _normalizeClass({
          'opacity-20': !__props.preDate || modelTypeDate.value == 'all',
        })
              }, null, 8, ["class"])
            ], 2))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          (__props.typeDateArray.length > 1)
            ? (_openBlock(), _createBlock(_unref(BInputSelect), {
                key: 0,
                modelValue: modelTypeDate.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((modelTypeDate).value = $event)),
                source: 
          _unref(defaultTypeDates).filter((item) => __props.typeDateArray.includes(item.value))
        ,
                itemTextKey: "label",
                itemValueKey: "value",
                class: "w-full cursor-pointer px-4 text-md",
                noBorder: ""
              }, null, 8, ["modelValue", "source"]))
            : _createCommentVNode("", true),
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "w-full" }, null, -1)),
          (__props.typeDate != 'custom' && __props.typeDate != 'all')
            ? (_openBlock(), _createBlock(BCustomDatePicker, {
                key: 1,
                modelValue: modelDate.value,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((modelDate).value = $event)),
                typeDate: (modelTypeDate.value as any),
                class: _normalizeClass(["hover_picker inline-flex min-w-30 cursor-pointer items-center justify-center self-center rounded-lg border-2 bg-content", {
          day_picker: modelTypeDate.value == 'day',
          week_picker: modelTypeDate.value == 'week',
          month_picker: modelTypeDate.value == 'month',
          year_picker: modelTypeDate.value == 'year',
        }])
              }, null, 8, ["modelValue", "typeDate", "class"]))
            : _createCommentVNode("", true),
          (__props.typeDate == 'custom')
            ? (_openBlock(), _createBlock(BCustomRangeDatePicker, {
                key: 2,
                start: __props.date[0],
                end: __props.date[1],
                class: "day_picker cursor-pointer rounded-lg border-2 bg-content",
                "onUpdate:date": _cache[3] || (_cache[3] = ($event: any) => (modelDate.value = $event))
              }, null, 8, ["start", "end"]))
            : _createCommentVNode("", true),
          (__props.typeDate == 'all')
            ? (_openBlock(), _createBlock(BCustomRangeDatePicker, {
                key: 3,
                start: __props.date[0],
                end: __props.date[1],
                disabled: "",
                class: "day_picker rounded-lg border-2 bg-content"
              }, null, 8, ["start", "end"]))
            : _createCommentVNode("", true)
        ]),
        (__props.canNavigate)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              onClick: _cache[4] || (_cache[4] = ($event: any) => (updateNavigationDate(__props.nextDate))),
              class: _normalizeClass(["flex h-full w-10 items-center justify-center", {
        'cursor-pointer hover:text-primary  ':
          __props.nextDate && modelTypeDate.value != 'all',
      }])
            }, [
              _createVNode(_component_BIcon, {
                name: "chevron_right",
                size: "lg",
                class: _normalizeClass({
          'opacity-20': !__props.nextDate || modelTypeDate.value == 'all',
        })
              }, null, 8, ["class"])
            ], 2))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})